import { Grid, Paper, Step, StepLabel, Stepper } from '@material-ui/core';
import React, { useState } from 'react';
import FillInfo from './FillInfo';
import Payment from './Payment';
import PrintLabel from './PrintLabel';
import Rate from './Rate';
import ShipEnvoy from './ShipEnvoy';

const steps = ['Fill Info', 'Rate', 'Payment', 'Print Label'];

// const useStyles = makeStyles((theme) => ({
//   root: {
//     flexGrow: 1,
//   },

//   button: {
//     margin: theme.spacing(3, 2, 3, 1),
//   },
// }));


export default function Shipping() {
  // const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);

  function getStepContent() {
    switch (activeStep) {
      case 0:
        return <FillInfo activeStep={activeStep} setActiveStep={setActiveStep} />;
      case 1:
        return <Rate activeStep={activeStep} setActiveStep={setActiveStep} />;
      case 2:
        return <Payment activeStep={activeStep} setActiveStep={setActiveStep} />;
      case 3:
        return <PrintLabel activeStep={activeStep} setActiveStep={setActiveStep} />;
      default:
        throw new Error('Unknown step');
    }
  }

  // const handleNext = () => {
  //   setActiveStep(activeStep + 1);
  // };

  // const handleBack = () => {
  //   setActiveStep(activeStep - 1);
  // };

  return (
    <ShipEnvoy>
      <Paper>
        <Grid container justify="space-evenly">
          {/* Stepper */}
          <Grid item xs={12}>
            <Stepper activeStep={activeStep} color="secondary">
              {steps.map((step) => (
                <Step key={step}>
                  <StepLabel>{step}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Grid>
        </Grid>

        {getStepContent()}

      </Paper>
    </ShipEnvoy>
  );
}