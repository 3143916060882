import { Button, Checkbox, CircularProgress, Grid, IconButton, InputAdornment, Paper, Snackbar, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import MuiAlert from '@material-ui/lab/Alert';
import firebase from 'firebase/app';
import { Form, Formik, useField } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import * as yup from 'yup';
import BillingTemplate from "../common/BillingTemplate";
import { AuthContext } from "../firebase/AuthContext";
import { firestore } from "../firebase/config";
import ShipEnvoy from './ShipEnvoy';
import CountryProvince from '../common/CountryProvince'


const initialValues = {
  personalInfo: {
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    address1: "",
    address2: "",
    city: "",
    postalCode: "",
    province: "ON",
    country: "CA",
  },

  password: {
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  },


}

const validationSchema = {
  personalInfo: yup.object({
    firstName: yup.string().required("First name is required."),
    lastName: yup.string().required("Last name is required."),
    phone: yup.number().typeError("That doesn't look like a phone number!").integer("That doesn't look like a phone number!").positive("That doesn't look like a phone number!").required("Phone is required."),
    email: yup.string().required("Email is required."),
    address1: yup.string().required("Address is required."),
    address2: "",
    city: yup.string().required("City is required."),
    postalCode: yup.string().required("Postal code is required."),
    province: yup.string().required("Province is required."),
    country: yup.string().required("Country is required."),
  }),

  password: yup.object({
    currentPassword: yup.string(),
    newPassword: yup.string().min(8, "Password must contain 8 or more characters."),
    confirmPassword: yup.string().oneOf([yup.ref(`newPassword`), null], 'Passwords must match'),
  })

}


const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    padding: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderTopRightRadius: "20px",
  },
  header: {
    // backgroundColor: "rgb(128,128,128, 0.4)",
    // backgroundColor: "#123c63",
    // borderBottom: `1px solid ${theme.palette.primary.main}`,
    borderTopRightRadius: "20px",
  },

  container: {
    width: "80%",
    [theme.breakpoints.down("xs")]: {
      width: "90%"
    }
  }
}));


export default function Account(props) {
  const classes = useStyles();

  const { currentUser } = useContext(AuthContext);
  const uid = currentUser ? currentUser.uid : sessionStorage.getItem("uid")

  const [isLoading, setLoading] = useState(true)
  const [isProcessingPersonalInfo, setIsProcessingPersonalInfo] = useState(false)
  const [isProcessingPassword, setIsProcessingPassword] = useState(false)


  const [errorMessage, setErrorMessage] = useState(null)

  const [snackMessage, setSnackMessage] = useState(null)
  const [openSnacFail, setOpenSnaFail] = useState(false);
  const [openSnaSucc, setopenSnaSucc] = useState(false);

  const [editPersonal, setPersonal] = useState(false)
  const [editPassword, setPassword] = useState(false)
  const [showPassword, setShowPassword] = useState(false)


  

  useEffect(() => {


    setLoading(true)
    let pi = initialValues.personalInfo
    let docRef = firestore.collection("Customer").where("customer_id", "==", uid)
    docRef.get().then((doc) => {
      doc.forEach(doc => {
        const ref = doc.data()
        pi.firstName = ref.first_name
        pi.lastName = ref.last_name
        pi.phone = ref.phone
        pi.email = ref.email
        pi.address1 = ref.address1
        pi.address2 = ref.address2
        pi.city = ref.city
        pi.postalCode = ref.postal_code
        pi.province = ref.province
        pi.country = ref.country
      })
      setLoading(false)
    }).catch((error) => {
      console.log("Error getting document:", error);
      setSnackMessage(error.message)
      setOpenSnaFail(true)
      setLoading(false)
    });
  }, [])


  /////// submit//////////
  const submitPersonal = values => {
    setIsProcessingPersonalInfo(true)
    let docRef = firestore.collection("Customer").doc(uid)
    docRef.update({
      first_name: values.firstName,
      last_name: values.lastName,
      phone: values.phone,
      email: values.email,
      address1: values.address1,
      address2: values.address2,
      city: values.city,
      postal_code: values.postalCode,
      province: values.province,
      country: values.country
    }).then(querySnapshot => {
      setIsProcessingPersonalInfo(false)
      setPersonal(false)
      setSnackMessage("Update personal information successful")
      setopenSnaSucc(true)
      console.log("Update personal information successful")
    }).catch(error => {
      setIsProcessingPersonalInfo(false)
      setPersonal(false)
      setSnackMessage(error.message)
      setOpenSnaFail(true)
      console.log("Update personal information failed")
    })

  }

  const submitPassword = (values, props) => {
    setIsProcessingPassword(true)
    let { currentPassword, newPassword, confirmPassword } = values
    if (editPassword & newPassword === confirmPassword) {
      firebase.auth()
        .signInWithEmailAndPassword(initialValues.personalInfo.email, currentPassword)
        .then(function (user) {
          firebase.auth().currentUser.updatePassword(newPassword).then(function () {
            console.log("Update passowrd successful")
            setSnackMessage("Update password successful")
            setErrorMessage(null)
            setopenSnaSucc(true)
            // setSubmit(submit + 1)
            setIsProcessingPassword(false)
            props.resetForm()
            setShowPassword(false)
            setPassword(false)
          }).catch(function (error) {
            setIsProcessingPassword(false)
            setSnackMessage(error.message)
            setOpenSnaFail(true)
            console.log("update passowrd failed", error.message)
          });

        }).catch(function (error) {
          setIsProcessingPassword(false)
          setErrorMessage(error.message)
          console.log("passowrd is wrong", error.message)
        });
    }
  }


  /////////////////////////////////////////

  const snackFail = () => {
    setOpenSnaFail(false)
  }
  const snackSuccess = () => {
    setopenSnaSucc(false)
  }
  const handleClickShowPassword = e => {
    setShowPassword(e.target.checked);
  };



  //////////toggle edit//////////
  const togglePersonal = formProps => {
    setPersonal(!editPersonal)
    if (editPersonal) {
      formProps.resetForm()
    }

  }

  const togglePassword = formProps => {
    setPassword(!editPassword)
    setErrorMessage(null)
    setShowPassword(false)
    if (editPassword) {
      formProps.resetForm()
    }
  }



  const TextFieldHelper = (props) => {
    const [field, meta] = useField(props);
    const helperText = !!meta.error && meta.touched && !props.disabled ? meta.error : "";
    const error = !!meta.error && meta.touched && !props.disabled
    return (<TextField {...props} {...field} helperText={helperText} error={error} variant="outlined" fullWidth size="small" />);
  };

  const PasswordFieldHelper = (props) => {
    const [field, meta] = useField(props);
    let helperText
    let error
    if (field.name !== "currentPassword") {
      helperText = !!meta.error && meta.touched && editPassword ? meta.error : "";
      error = !!meta.error && meta.touched && editPassword
    } else {
      helperText = errorMessage
      error = !errorMessage ? false : true
    }

    return (
      <TextField {...props} {...field} helperText={helperText} error={error} variant="outlined" fullWidth size="small" disabled={!editPassword} type={showPassword ? 'text' : 'password'} InputProps={{
        endAdornment:
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              edge="end"
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>

      }} />
    );

  }



  if (isLoading) {
    return (
      <ShipEnvoy>
        <Grid container justify="center" alignItems="center" style={{ minHeight: "75vh" }}>
          <CircularProgress />
        </Grid>
      </ShipEnvoy>
    )
  } else {
    return (
      <ShipEnvoy>
        <Grid className={classes.root} container direction="column" justify="center" alignItems="center" spacing={3} >

          <Grid className={classes.container} item container justify="center" alignItems="center" >
            <Formik initialValues={initialValues.personalInfo} validationSchema={validationSchema.personalInfo} onSubmit={submitPersonal} >
              {formProps => {

                return (
                  <Form>
                    <Paper className={classes.paper} variant="outlined" elevation={0}>
                      <Grid container spacing={3}>
                        <Grid className={classes.header} item container  >
                          <Grid item xs={6}><Typography variant="h5">Personal Info</Typography></Grid>
                          <Grid item container xs={6} justify="flex-end" alignItems="center" spacing={1}>
                            <Grid item>
                              <Button type="submit" size="small" variant="contained" color="primary" startIcon={<SaveIcon />} style={{ display: editPersonal ? "" : "none" }}>
                                {isProcessingPersonalInfo ? <CircularProgress size={20} style={{ color: "white" }} /> : "save"}
                              </Button>
                            </Grid>
                            <Grid item >
                              <Button size="small" onClick={togglePersonal.bind(null, formProps)} >
                                {editPersonal ? "cancel" : "edit"}
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item container spacing={3}>
                          <Grid item xs={12} md={3}><TextFieldHelper name="firstName" label="First Name" disabled={!editPersonal} /></Grid>
                          <Grid item xs={12} md={3}><TextFieldHelper name="lastName" label="Last Name" disabled={!editPersonal} /></Grid>
                          <Grid item xs={12} md={3}><TextFieldHelper name="email" label="Email" disabled={true} /></Grid>
                          <Grid item xs={12} md={3}><TextFieldHelper name="phone" label="Phone" disabled={!editPersonal} /></Grid>
                        </Grid>
                        <Grid item container spacing={3}>
                          <Grid item xs={12} md={6}><TextFieldHelper name="address1" label="Address 1" disabled={!editPersonal} /></Grid>
                          <Grid item xs={12} md={6}><CountryProvince disabled={!editPersonal} {...formProps} /></Grid>
                        </Grid>
                        <Grid item container spacing={3}>
                          <Grid item xs={12} md={6}><TextFieldHelper name="address2" label="Address 2" disabled={!editPersonal} /></Grid>
                          <Grid item xs={12} md={3}><TextFieldHelper name="city" label="City" disabled={!editPersonal} /></Grid>
                          <Grid item xs={12} md={3}><TextFieldHelper name="postalCode" label="ZIP/Postal Code" disabled={!editPersonal} /></Grid>
                        </Grid>
                      </Grid>
                    </Paper>

                  </Form>
                )
              }}
            </Formik>
          </Grid>

          <Grid className={classes.container} item container justify="center" alignItems="center">
            <Formik initialValues={initialValues.password} validationSchema={validationSchema.password} onSubmit={submitPassword}>
              {formProps => {
                return (
                  <Form>
                    <Paper className={classes.paper} variant="outlined" elevation={0}>
                      <Grid container spacing={3}>
                        <Grid className={classes.header} item container  >
                          <Grid item xs={6}><Typography variant="h5">Login Password</Typography></Grid>
                          <Grid item container xs={6} justify="flex-end" alignItems="center" spacing={1}>
                            <Grid item>
                              <Button type="submit" size="small" variant="contained" color="primary" startIcon={<SaveIcon />} style={{ display: editPassword ? "" : "none" }}>
                                {isProcessingPassword ? <CircularProgress size={20} style={{ color: "white" }} /> : "save"}
                              </Button>
                            </Grid>
                            <Grid item >
                              <Button size="small" onClick={togglePassword.bind(null, formProps)} >
                                {editPassword ? "cancel" : "edit"}
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item container spacing={3}>
                          <Grid item xs={12} md={6}><PasswordFieldHelper name="currentPassword" label="Current Password" error={true} /></Grid>
                          <Grid item container xs={12} md={6} justify="flex-start" alignItems="center">
                            <Checkbox color="primary" onChange={handleClickShowPassword} disabled={!editPassword} checked={showPassword} />
                            <Typography>Show Password</Typography>
                          </Grid>
                        </Grid>
                        <Grid item container spacing={3}>
                          <Grid item xs={12} md={6}><PasswordFieldHelper name="newPassword" label="New Passowrd" /></Grid>
                          <Grid item xs={12} md={6}><PasswordFieldHelper name="confirmPassword" label="Confirm Password" /></Grid>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Form>
                )
              }}
            </Formik>
          </Grid>



          <Grid className={classes.container} item  >
            <BillingTemplate />
          </Grid>

          <Snackbar open={openSnaSucc} autoHideDuration={6000} onClose={snackSuccess} >
            <MuiAlert onClose={snackSuccess} elevation={6} variant="filled" severity="success" >
              {snackMessage}
            </MuiAlert>
          </Snackbar>
          <Snackbar open={openSnacFail} autoHideDuration={6000} onClose={snackFail} >
            <MuiAlert onClose={snackFail} elevation={6} variant="filled" severity="error">
              {snackMessage}
            </MuiAlert >
          </Snackbar>
        </Grid>

      </ShipEnvoy >
    );
  }
}
















