import React, { useState, useEffect, useContext } from "react";
import {
    CardElement,
    useStripe,
    useElements,
} from "@stripe/react-stripe-js";

import { Elements } from "@stripe/react-stripe-js";
import { Button, Paper, Grid, TextField, Typography, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save';


import { AuthContext } from "../firebase/AuthContext"
import firebase from 'firebase/app';
import functions from 'firebase/functions'
import { firestore } from "../firebase/config"







const cardStyle = {
    style: {
        base: {

            color: "inherit",
            fontFamily: 'inherit',
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
                color: "1px solid rgba(128,128,128, 0.5)",
            }
        },
        invalid: {
            color: "red",
            iconColor: "red"
        },
        // complete: {
        //     color: "green",
        //     iconColor: "green"
        // }
        value: {
            card: "222222"
        }
    }
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    paper: {
        width: "100%",
        padding: theme.spacing(2),
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderTopRightRadius: "20px",

    },
    cardElement: {
        font: "inherit",
        color: "currentColor",
        border: "1px solid rgba(128,128,128, 0.5)",
        borderRadius: "5px",
        height: "1.1876em",
        margin: "0",
        display: "block",
        padding: "10.5px  14px",
        minWidth: "0",
        background: "none",
        boxSizing: "content-box",
        animationName: "mui-auto-fill-cancel",
        letterSpacing: "inherit",
        animationDuration: "10ms",
        webkitTapHighightColor: "transparent",
    },
    header: {
        // backgroundColor: "rgb(128,128,128, 0.4)",
        // backgroundColor: "#123c63",
        // borderBottom: `1px solid ${theme.palette.primary.main}`,
        borderTopRightRadius: "20px",
    },
}));


const setupIntent = firebase.functions().httpsCallable('setupIntent')
const addPaymentMethodDetails = firebase.functions().httpsCallable('addPaymentMethodDetails')




function CheckoutForm() {
    const classes = useStyles();
    const { currentUser } = useContext(AuthContext);
    const uid = currentUser ? currentUser.uid : sessionStorage.getItem("uid")

    const [name, setName] = useState('')
    const [last4, setLast4] = useState("")
    const [exp, setExp] = useState("")
    const [postalCode, setPostalCode] = useState("")

    const [isLoading, setLoading] = useState(true)
    const [succeeded, setSucceeded] = useState(false);
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [clientSecret, setClientSecret] = useState('');
    const [editPayment, setEditPayment] = useState(false)
    const [submit, setSubmit] = useState(1)

    const stripe = useStripe();
    const elements = useElements();


    useEffect(() => {
        //////retreive stripe info from firestore////
        let docRef = firestore.collection("Customer").doc(uid)
        docRef.get().then(doc => {
            let data = doc.data()
            let CardHolderName = data.card_holder_name ? data.card_holder_name : ""
            setName(CardHolderName)
            if (data.stripe_payment.payment_method) {
                let month = data.stripe_payment.payment_method.card.exp_month.toString()
                let modifyMonth = month.length === 1 ? "0" + month : month
                let year = data.stripe_payment.payment_method.card.exp_year.toString().slice(1, 3)
                // setName(data.stripe_payment.payment_method.billing_details.name)
                setLast4(data.stripe_payment.payment_method.card.last4)
                setExp(modifyMonth + "/" + year)
                setPostalCode(data.stripe_payment.payment_method.billing_details.address.postal_code)
            }

        }).catch(err => {
            console.log(err)
        })


        // Create PaymentIntent as soon as the page loads
        if (editPayment) {
            let param = { firestore_customer_id: uid }
            setupIntent(param).then(res => {
                setClientSecret(res.data.payment_intent.client_secret)
            }).catch(err => {
                console.log("this is error", err)
            })
        }



        setLoading(false)
    }, [submit, editPayment]);
    const toggleEdit = () => {
        setEditPayment(!editPayment)
    }
    const handleNameChange = e => {
        setName(e.target.value)
    }

    const handleChange = async (event) => {
        // Listen for changes in the CardElement
        // and display any errors as the customer types their card details
        setDisabled(event.empty);
        setSucceeded(false)
        setError(event.error ? event.error.message : "");
    };


    const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            setDisabled(true)
            return;
        }

        let docRef = firestore.collection("Customer").doc(uid)
        docRef.update({ card_holder_name: name }).then(res => {
            console.log(res)
        }).catch(error => {
            console.log(error)
        })

        const result = await stripe.confirmCardSetup(clientSecret, {
            payment_method: {
                card: elements.getElement(CardElement),
                billing_details: {
                    // name:name
                },
            }
        })

        if (result.error) {
            // Display result.error.message in your UI.
            console.log(result)
            setError(result.error.message)
            setProcessing(false)
        } else {
            // The setup has succeeded. Display a success message and send
            // result.setupIntent.payment_method to your server to save the
            // card to a Customer

            const { payment_method } = result.setupIntent
            let param = {
                payment_method_id: payment_method,
                firebase_customer_id: uid
            }

            addPaymentMethodDetails(param).then(res => {
                setSucceeded(true)
                setEditPayment(!editPayment)
                setProcessing(false)
                setSubmit(submit + 1)
                console.log("updated!!")
            }).catch(err => {
                console.log(err)
            })

        }
    };


    const displayCardHelper = () => {
        return (
            <Grid container spacing={1}>
                <Grid item md={8} xs={12}><TextField value={last4.length === 0 ? "" : `**** **** **** ${last4}`} placeholder="Card Number" disabled={true} /></Grid>
                <Grid item md={2} xs={6}><TextField value={exp} placeholder="MM/YY" disabled={true} /></Grid>
                <Grid item md={2} xs={6}><TextField value={postalCode} placeholder="CVC" disabled={true} /></Grid>
            </Grid >
        )
    }
    if (isLoading) {
        return (
            <form onSubmit={handleSubmit}>
                <Paper className={classes.paper}>
                    <Grid container justify="center" alignItems="center" spacing={3}>
                        <Grid className={classes.header} item container  >
                            <Grid item xs={6}><Typography variant="h5">Payment Info</Typography></Grid>
                            <Grid item container xs={6} justify="flex-end" alignItems="center" spacing={1}>
                                <Grid item>
                                    <Button
                                        disabled={processing || disabled || succeeded || error}
                                        id="submit"
                                        type="submit"
                                        size="small"
                                        startIcon={<SaveIcon />}
                                        style={{ display: editPayment ? "" : "none" }}
                                    >

                                        {processing ? <CircularProgress size={20} /> : "save"}

                                    </Button>
                                </Grid>
                                <Grid item >
                                    <Button size="small" onClick={toggleEdit} >
                                        {editPayment ? "cancel" : "edit"}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <CircularProgress />
                        </Grid>
                    </Grid>
                </Paper>
            </form>
        )
    } else {
        return (
            <form onSubmit={handleSubmit}>
                <Paper className={classes.paper} variant="outlined" elevation={0}>
                    <Grid container justify="center" alignItems="center" spacing={3}>
                        <Grid className={classes.header} item container  >
                            <Grid item xs={6}><Typography variant="h5">Payment Info</Typography></Grid>
                            <Grid item container xs={6} justify="flex-end" alignItems="center" spacing={1}>
                                <Grid item>
                                    <Button
                                        disabled={processing || disabled || error}

                                        type="submit"
                                        size="small"
                                        startIcon={<SaveIcon />}
                                        style={{ display: editPayment ? "" : "none" }}
                                    >

                                        {processing ? <CircularProgress size={20} /> : "save"}

                                    </Button>
                                </Grid>
                                <Grid item >
                                    <Button size="small" onClick={toggleEdit} >
                                        {editPayment ? "cancel" : "edit"}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField label="Cardholder name" fullWidth value={name} onChange={handleNameChange} required disabled={!editPayment} />
                        </Grid>
                        <Grid item xs={12}>
                            {!editPayment && displayCardHelper()}
                            <Grid item style={{ display: editPayment ? "" : "none" }}>
                                <CardElement id="card-element" options={cardStyle} className={classes.cardElement} onChange={handleChange} />
                                {error ? <span style={{ color: "red" }}>{error}</span> : ""}
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </form>

        );
    }

}
/////////////////////////////////////////////////
// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// loadStripe is initialized with a fake API key.
// Sign in to see examples pre-filled with your key.

export default function BillingTemplate() {
    const stripePromise = useStripe();
    return (
        <div className="App">
            <Elements stripe={stripePromise}>
                <CheckoutForm />
            </Elements>
        </div>
    );
}