import React, { createContext, useEffect, useState } from "react";
import { auth } from "./config";

export const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setCurrentUser(user)
      if (user) {

        sessionStorage.setItem("uid", user.uid)
      }
    });

    return unsubscribe;
  }, []);

  function signup(email, password) {
    return auth.createUserWithEmailAndPassword(email, password);
  }

  function login(email, password) {
    return auth.signInWithEmailAndPassword(email, password);
  }

  function logout() {
    return auth.signOut();
  }

  function resetPassword(email) {
    return auth.sendPasswordResetEmail(email);
  }

  function updatePassword(password) {
    return currentUser.updateEmail(password);
  }

  function updateEmail(email) {
    return currentUser.updateEmail(email);
  }

  const value = { currentUser, login, signup, logout, resetPassword, updateEmail, updatePassword, }

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );

}