import { loadStripe } from '@stripe/stripe-js';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Elements } from '@stripe/react-stripe-js';

const stripePromise = loadStripe("pk_test_51IR8tqFZdrnitSBRxCKPQKSLJj20PxwYmf3Cup1qcmLaCEaZywdTKI6WD5N1vAwygviQihffiDM2WvvDP4cG7AOX00AjUBRSyl");

ReactDOM.render(
  // <React.StrictMode>
  //   </React.StrictMode>
  <Elements stripe={stripePromise}>
    <App />
  </Elements>,
  document.getElementById('root')
);

