import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  shipperName: "",
  shipperAddressSaved: true,
  shipperAddress1: "",
  shipperAddress2: "",
  shipperCountry: "CA",
  shipperCity: "",
  shipperProvince: "ON",
  shipperPostalCode: "",
  shipperPhone: "",
  shipperEmail: "",
  shipDate: Date.now(),
  confirmDelivery: false,
  shipperInstructions: "",

  receiverName: "",
  receiverAddressSaved: true,
  receiverAddress1: "",
  receiverAddress2: "",
  receiverCountry: "CA",
  receiverCity: "",
  receiverProvince: "ON",
  receiverPostalCode: "",
  receiverPhone: "",
  receiverEmail: "",
  signatureRequired: false,
  receiverInstructions: "",

  packageType: "carrier",
  packageTypeFedex: "",
  packageTypePurolator: "",
  packageTypeDhl: "",
  carrierPackageWeight: "",
  carrierPackageDesc: "",

  carrierPackagesFedex: [],
  carrierPackagesDhl: [],
  carrierPackagesPurolator: [],

  customerPackageLength: 1,
  customerPackageWidth: 1,
  customerPackageHeight: 1,
  customerPackageWeight: 1,
  customerPackageDesc: "",

  processedRates: null,
  customerType: 'regular', // this field is to check customer's payment type.

  rateInfo: null,
  numberOfPackage: 1,
  contents: "",
  non_delivery: "",
  taxable_entity_type: "",
  identifier_type: "",
  issuing_authority: "",
  value: "",
  skipRequery: false,

  customs_items: [{ description: "", quantity: 1, value: 0, harmonized_tariff_code: "", country_of_origin: "", unit_of_measure: "", sku: "" }],
  custom: null, // processed 'customs_items' and will upload to firebase.
  //credit card
  useCurrentCard: true,
  cardHolderName: "",
  last4: "**** **** **** 4242",
  expire_date: "01/99",
  postal_code: "",
};

const shipping = createSlice({
  name: "shipping",
  initialState: initialState,
  reducers: {
    saveShippingInfo: (state, action) => {
      const payload = action.payload;

      state.shipperName = payload.shipperName;
      state.shipperAddressSaved = payload.shipperAddressSaved;
      state.shipperAddress1 = payload.shipperAddress1;
      state.shipperAddress2 = payload.shipperAddress2;
      state.shipperCountry = payload.shipperCountry;
      state.shipperCity = payload.shipperCity;
      state.shipperProvince = payload.shipperProvince;
      state.shipperPostalCode = payload.shipperPostalCode;
      state.shipperPhone = payload.shipperPhone;
      state.shipperEmail = payload.shipperEmail;
      state.shipDate = payload.shipDate;
      state.confirmDelivery = payload.confirmDelivery;
      state.shipperInstructions = payload.shipperInstructions;

      state.receiverName = payload.receiverName;
      state.receiverAddressSaved = payload.receiverAddressSaved;
      state.receiverAddress1 = payload.receiverAddress1;
      state.receiverAddress2 = payload.receiverAddress2;
      state.receiverCountry = payload.receiverCountry;
      state.receiverCity = payload.receiverCity;
      state.receiverProvince = payload.receiverProvince;
      state.receiverPostalCode = payload.receiverPostalCode;
      state.receiverPhone = payload.receiverPhone;
      state.receiverEmail = payload.receiverEmail;
      state.receiverInstructions = payload.receiverInstructions;
      state.signatureRequired = payload.signatureRequired;

      state.packageType = payload.packageType;
      state.carrierPackageWeight = payload.carrierPackageWeight;
      state.carrierPackageDesc = payload.carrierPackageDesc;
      state.packageTypeDhl = payload.packageTypeDhl;
      state.packageTypeFedex = payload.packageTypeFedex;
      state.packageTypePurolator = payload.packageTypePurolator;

      state.customerPackageWidth = payload.customerPackageWidth;
      state.customerPackageHeight = payload.customerPackageHeight;
      state.customerPackageLength = payload.customerPackageLength;
      state.customerPackageWeight = payload.customerPackageWeight;
      state.customerPackageDesc = payload.customerPackageDesc;

      //contents
      state.contents = payload.contents;
      state.non_delivery = payload.non_delivery;
      state.customs_items = payload.customs_items;
      state.skipRequery = false;
    },
    saveRateInfo: (state, action) => {
      // console.log(action.payload);
      state.rateInfo = action.payload;
    },
    savePackageTypeFedex: (state, action) => {
      state.carrierPackagesFedex = action.payload;
    },
    savePackageTypeDhl: (state, action) => {
      state.carrierPackagesDhl = action.payload;
    },
    savePackageTypePurolator: (state, action) => {
      state.carrierPackagesPurolator = action.payload;
    },
    saveProcessedRates: (state, action) => {
      state.customerType = action.payload.customerType;
      state.processedRates = action.payload.processedRates;

      state.skipRequery = true;
    },
    saveCreditCard: (state, action) => {
      const payload = action.payload
      state.cardHolderName = payload.cardHolderName
      state.expire_date = `${payload.modifyMonth}/${payload.year}`
      state.postal_code = payload.postal_code
      state.useCurrentCard = true
    },
    setInitialRate: (state, action) => {
      // const payload = action.payload;
      // state["se-538063_Service"] = action["se-538063_Service"] //fedEx
      // state["se-537961_Service"] = action["se-537961_Service"];  //dhl
      // state["se-537955_Service"] = action["se-537955_Service"];  //puralator
    },
    clearShippingInfo: () => initialState,

  },

});

export const selectShipperAddress1 = (shipping) => `${shipping.shipperAddress1},`;
export const selectShipperAddress2 = (shipping) => `${shipping.shipperCity}, ${shipping.shipperProvince}, ${shipping.shipperCountry}`;
export const selectShipperAddress3 = (shipping) => `${shipping.shipperPostalCode}`;
export const selectReceiverAddress1 = (shipping) => `${shipping.receiverAddress1},`;
export const selectReceiverAddress2 = (shipping) => `${shipping.receiverCity}, ${shipping.receiverProvince}, ${shipping.receiverCountry}`;
export const selectReceiverAddress3 = (shipping) => `${shipping.receiverPostalCode}`;

export const {
  saveShippingInfo,
  clearShippingInfo,
  setInitialRate,
  saveRateInfo,
  savePackageTypeDhl,
  savePackageTypePurolator,
  savePackageTypeFedex,
  saveProcessedRates,
  saveCreditCard,
} = shipping.actions;

export default shipping.reducer;