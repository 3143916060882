import { Grid, MenuItem } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BcTextField } from './BcComponents';
import { fetchCountry, fetchRegion } from '../store/countrySlice';

export default function CountryProvince(props) {
  const { setFieldValue, handleChange, disabled, } = props;
  const dispatch = useDispatch();
  const countries = useSelector((state) => state.country.countries);
  const regions = useSelector((state) => state.country.regions);
  const selectedCountry = props.values.country;

  useEffect(() => {
    async function fetchData() {
      if (countries.list.length <= 1) {
        dispatch(fetchCountry());
      }

      if (regions.list.length <= 1) {
        dispatch(fetchRegion('CA'));
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      dispatch(fetchRegion(selectedCountry));
    }
    fetchData();
  }, [selectedCountry]);

  const handleCountryChange = (e) => {
    handleChange(e);
    setFieldValue("province", "", false);
  }

  return (
    props.useForSignUp ? <>
      <Grid item xs={12} md={6}>
        <BcTextField select name="country" label="Country" onChange={handleCountryChange} disabled={disabled}>
          {countries.list.map((country, index) => (<MenuItem key={index} value={country.short_code}>{country.full_name}</MenuItem>))}
        </BcTextField>
      </Grid>

      <Grid item xs={12} md={6}>
        <BcTextField select name="province" label="Province" disabled={disabled} required >
          {regions.list.map((region, index) => (<MenuItem key={index} value={region.shortCode}>{region.name}</MenuItem>))}
        </BcTextField>
      </Grid>
    </>
      : <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <BcTextField select name="country" label="Country" onChange={handleCountryChange} disabled={disabled}>
            {countries.list.map((country, index) => (<MenuItem key={index} value={country.short_code}>{country.full_name}</MenuItem>))}
          </BcTextField>
        </Grid>

        <Grid item xs={12} md={6}>
          <BcTextField select name="province" label="Province" disabled={disabled} required >
            {regions.list.map((region, index) => (<MenuItem key={index} value={region.shortCode}>{region.name}</MenuItem>))}
          </BcTextField>
        </Grid>
      </Grid >

  );
}