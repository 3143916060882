import { Checkbox, FormControlLabel, Radio, TextField } from '@material-ui/core';
import { useField } from 'formik';
import React from 'react';

export const BcTextField = (props) => {
  const [field, meta] = useField(props);
  const error = meta.error && meta.touched ? meta.error : "";
  return (<TextField {...field} {...props} helperText={error} error={!!error} />);
};

export const BcRadio = ({ label, ...props }) => {
  const [field] = useField(props);
  return (<FormControlLabel {...field} control={<Radio color="primary" />} label={label} />);
};

export const BcCheckbox = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (<FormControlLabel {...field} {...props} control={<Checkbox color="primary" checked={meta.value} />} label={label} />);
};
