import { firestore } from "../firebase/config";
import md5 from "./md5";
import firebase from 'firebase/app';

async function saveAddress(values, isShipping, currentUser) {
  const person = isShipping ? "shipper" : "receiver";
  const addressEntry = {
    "name": values[`${person}Name`],
    "address1": values[`${person}Address1`],
    "address2": values[`${person}Address2`],
    "country": values[`${person}Country`],
    "province": values[`${person}Province`],
    "city": values[`${person}City`],
    "postal_code": values[`${person}PostalCode`],
    "phone": values[`${person}Phone`],
    "email": values[`${person}Email`],
    "is_shipping": isShipping,
  };

  let combinedString = "";
  for (const key in addressEntry) {
    combinedString += addressEntry[key];
  }

  const clientAddressMd5 = md5(combinedString);

  const userRef = firestore.collection("Customer").doc(currentUser.uid);
  const userData = (await userRef.get()).data();
  const userAddresses = userData.addresses;
  const duplicateAddress = userAddresses.find(address => address.id === clientAddressMd5);
  if (!duplicateAddress) {
    addressEntry["id"] = clientAddressMd5;
    await userRef.update({ addresses: firebase.firestore.FieldValue.arrayUnion(addressEntry) });
  }
}

export default async function saveCustomerAddress(values, currentUser) {

  if (values.shipperAddressSaved) {
    // save shipper address.
    await saveAddress(values, true, currentUser);
  }

  if (values.receiverAddressSaved) {
    // save receiver address.
    await saveAddress(values, false, currentUser);
  }
}