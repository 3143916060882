import { Grid } from '@material-ui/core';
import React from 'react';
import * as yup from "yup";
import { BcTextField } from '../common/BcComponents';
import CountryProvince from './CountryProvince';

export const addressValidationSchema = {
  address1: yup.string().required("Address 1 is required."),
  // shipperAddress2: yup.string().required("Address 2 is required."),
  country: yup.string().required("Country is required."),
  province: yup.string().required("Province is required."),
  city: yup.string().required("City is required."),
  postalCode: yup.string().required("Postal code is required."),
};

export default function Address(props) {

  return (
    <React.Fragment>

      <Grid item xs={12}>
        <BcTextField label="Address 1" name="address1" required autoComplete="street-address" />
      </Grid>

      <Grid item xs={12}>
        <BcTextField label="Address 2" name="address2" autoComplete="address-line2" />
      </Grid>

      <CountryProvince {...props} useForSignUp={true} />

      <Grid item xs={12} md={6}>
        <BcTextField label="City" name="city" required autoComplete="address-level2" />
      </Grid>

      <Grid item xs={12} md={6}>
        <BcTextField label="Zip/Postal" name="postalCode" autoComplete="postal-code" />
      </Grid>

    </React.Fragment>
  );
}