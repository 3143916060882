import { UseTestData } from "./utility";

const __label = {
  "data": {
    "data": {
      "shipper_name": "Ellie Du",
      "shipper_address1": "374 Sheppard Ave E",
      "shipper_address2": "",
      "shipper_city": "North York",
      "shipper_country": "CA",
      "shipper_province": "ON",
      "shipper_postal_code": "M2N 3B6",
      "shipper_email": "fancy_people@gmail.com",
      "shipper_phone": "9056722339",
      "shipper_instruction": "A gift.",
      "shipper_confirm_delivery": false,
      "receiver_name": "Nancy Hudlow",
      "receiver_address1": "28 Bowerbank Dr",
      "receiver_address2": "Unit 203",
      "receiver_city": "North York",
      "receiver_country": "CA",
      "receiver_province": "ON",
      "receiver_postal_code": "M2M 1Z8",
      "receiver_email": "nancy.hudlow@outlook.com",
      "receiver_phone": "4164328999",
      "receiver_instruction": "",
      "receiver_signature_required": false,
      "description": "A birthday gift",
      "weight_unit": "pound",
      "dimension_unit": "inch",
      "is_carrier_package": true,
      "carrier_package_type": "carrier",
      "height": 1,
      "length": 1,
      "width": 1,
      "weight": 1,
      "status": "Label Created",
      "rate_info": {
        "rate_id": "se-523024938",
        "rate_type": "shipment",
        "carrier_id": "se-537955",
        "shipping_amount": {
          "currency": "usd",
          "amount": 12.93
        },
        "insurance_amount": {
          "currency": "usd",
          "amount": 0
        },
        "confirmation_amount": {
          "currency": "usd",
          "amount": 0
        },
        "other_amount": {
          "currency": "usd",
          "amount": 0
        },
        "zone": null,
        "package_type": null,
        "delivery_days": 1,
        "guaranteed_service": false,
        "estimated_delivery_date": "2021-03-11T00:00:00Z",
        "carrier_delivery_days": "1",
        "ship_date": "2021-03-10T00:00:00Z",
        "negotiated_rate": false,
        "service_type": "Purolator Ground",
        "service_code": "purolator_ground ",
        "trackable": true,
        "carrier_code": "purolator_ca",
        "carrier_nickname": "Purolator ",
        "carrier_friendly_name": "Purolator Canada",
        "validation_status": "valid",
        "warning_messages": [

        ],
        "error_messages": [

        ]
      },
      "envoy_rate_info": {
        "serviceType": "Purolator Ground",
        "rateId": "se-523024938",
        "shippingAmount": 16.353862260914998,
        "surcharge": 0,
        "totalToCustomer": 16.353862260914998,
        "totalToBranch": 16.353862260914998,
        "deliveryDays": 1
      },
      "service_info": {
        "label_id": "se-46753890",
        "status": "completed",
        "shipment_id": "se-88380398",
        "ship_date": "2021-03-10T00:00:00Z",
        "created_at": "2021-03-10T16:45:18.9990653Z",
        "shipment_cost": {
          "currency": "usd",
          "amount": 0
        },
        "insurance_cost": {
          "currency": "usd",
          "amount": 0
        },
        "tracking_number": "332808443979",
        "is_return_label": false,
        "rma_number": null,
        "is_international": false,
        "batch_id": "",
        "carrier_id": "se-537955",
        "service_code": "purolator_ground ",
        "package_code": "package",
        "voided": false,
        "voided_at": null,
        "label_format": "pdf",
        "display_scheme": "label",
        "label_layout": "4x6",
        "trackable": true,
        "label_image_id": null,
        "carrier_code": "purolator_ca",
        "tracking_status": "in_transit",
        "label_download": {
          "pdf": "https://api.shipengine.com/v1/downloads/10/y3CecEH6rECwsK1MoGXvWQ/label-46753890.pdf",
          "png": "https://api.shipengine.com/v1/downloads/10/y3CecEH6rECwsK1MoGXvWQ/label-46753890.png",
          "zpl": "https://api.shipengine.com/v1/downloads/10/y3CecEH6rECwsK1MoGXvWQ/label-46753890.zpl",
          "href": "https://api.shipengine.com/v1/downloads/10/y3CecEH6rECwsK1MoGXvWQ/label-46753890.pdf"
        },
        "form_download": null,
        "insurance_claim": null,
        "packages": [
          {
            "package_id": 49819419,
            "package_code": "package",
            "weight": {
              "value": 1,
              "unit": "pound"
            },
            "dimensions": {
              "unit": "inch",
              "length": 1,
              "width": 1,
              "height": 1
            },
            "insured_value": {
              "currency": "usd",
              "amount": 0
            },
            "tracking_number": "332808443979",
            "label_messages": {
              "reference1": null,
              "reference2": null,
              "reference3": null
            },
            "external_package_id": null,
            "sequence": 1
          }
        ],
        "charge_event": "carrier_default"
      },
      "paid_info": {

      },
      "custom": null,
      "ship_date": {
        "seconds": 1615392108927,
        "nanoseconds": 0
      },
      "customer_id": "O9jvgOe9Y7W1YPVdZhKNz59XK7p2",
      "created_date": {

      },
      "tracking_number": "332808443979_purolator_ca"
    },
    "status": 200
  }
}

export default function processLabel(rawLabel) {
  // console.log(rate);
  const label = UseTestData ? __label : rawLabel;
  const labelData = label.data.data;
  const serviceInfo = labelData.service_info;
  const rateInfo = labelData.rate_info;
  const envoy_rate_info = labelData.envoy_rate_info

  const processedLable = {
    labelId: serviceInfo.label_id,
    trackingNumber: serviceInfo.tracking_number,
    carrierCode: rateInfo.carrier_friendly_name,
    serviceCode: rateInfo.service_type,
    labelPngUrl: serviceInfo.label_download.png,
    labelPdfUrl: serviceInfo.label_download.pdf,
    shipmentCost: envoy_rate_info.shippingAmount,
    surcharge: envoy_rate_info.surcharge,
    totalAmount: envoy_rate_info.totalToCustomer,

    deliveryDay: rateInfo.carrier_delivery_days,
    status: labelData.status,
    shipperName: labelData.shipper_name,
    shipperAddress1: labelData.shipper_address1,
    shipperAddress2: `${labelData.shipper_city}, ${labelData.shipper_province}, ${labelData.shipper_country}`,
    shipperPostalCode: labelData.shipper_postal_code,

    receiverName: labelData.receiver_name,
    receiverAddress1: labelData.receiver_address1,
    receiverAddress2: `${labelData.receiver_city}, ${labelData.receiver_province}, ${labelData.receiver_country}`,
    receiverPostalCode: labelData.receiver_postal_code,
  };

  return processedLable;
}
