import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React from 'react';

export default function NoticeBar({ notice, setNotice }) {
  const handleClose = () => {
    setNotice({ ...notice, open: false });
  };

  return (
    <Snackbar open={notice.open} autoHideDuration={6000} onClose={handleClose}>
      <Alert onClose={handleClose} severity={notice.severity} elevation={6} variant="filled">
        {notice.message}
      </Alert>
      {/* { error.message &&
        <Collapse in={error.open}>
          <Box marginBottom={3}>
            <Alert severity="error" action={<IconButton color="inherit" size="small" onClick={() => { setError({ ...error, open: false }) }} >
              <CloseIcon fontSize="inherit" />
            </IconButton>}>
              {error.message}
            </Alert>
          </Box>
        </Collapse>
      } */}
    </Snackbar>
  );
}