import { CircularProgress, Container, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';


export default function Loading() {
  return (
    <Container maxWidth="md" style={{ padding: "10px", textAlign: "center" }}>
      <Typography variant="h6" color="secondary" gutterBottom>One moment, loading...</Typography>
      <CircularProgress color="secondary" />
      <Skeleton />
      <Skeleton animation="wave" />
      <Skeleton animation="wave" />
    </Container>
  );
}