import firebase from 'firebase/app';
import "firebase/auth";
import 'firebase/firestore';

//Bill's test firebase.
// var firebaseConfig = {
//   apiKey: "AIzaSyDw_fm2UAwd4nnRDLsPTGTD53bxZxq9wmk",
//   authDomain: "shipenvoy-8361e.firebaseapp.com",
//   projectId: "shipenvoy-8361e",
//   storageBucket: "shipenvoy-8361e.appspot.com",
//   messagingSenderId: "219932275573",
//   appId: "1:219932275573:web:1f9da11df31296525a193c"
// };

// ShipEnvoy's firebase.
var firebaseConfig = {
  apiKey: "AIzaSyBApGBuRXuD10dLYN667zKRmLKdwDmTGQw",
  authDomain: "envoy-dev-fbaca.firebaseapp.com",
  databaseURL: "https://envoy-dev-fbaca-default-rtdb.firebaseio.com",
  projectId: "envoy-dev-fbaca",
  storageBucket: "envoy-dev-fbaca.appspot.com",
  messagingSenderId: "334706981355",
  appId: "1:334706981355:web:c9cc902f57506d4252c1ae",
  measurementId: "G-JNKGNCDXZB"
};

export const app = firebase.initializeApp(firebaseConfig);
export const auth = app.auth();
export const firestore = firebase.firestore();


