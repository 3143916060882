import React, { useEffect, useState } from 'react';
import PrintLabel from '../components/PrintLabel';
import ShipEnvoy from '../components/ShipEnvoy';

export default function Track() {
  return (
    <ShipEnvoy>
      <PrintLabel />
    </ShipEnvoy>
  );
}