import { Avatar, Box, Button, Collapse, Grid, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CloseIcon } from '@material-ui/data-grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { Alert } from '@material-ui/lab';
import { Form, Formik } from 'formik';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as yup from "yup";
import { BcTextField } from '../common/BcComponents';
import { AuthContext } from '../firebase/AuthContext';
import UserFrame from './UserFrame';

const initialValues = {
  email: "",
  password: "",
};

const validationSchema = yup.object({
  email: yup.string().required("Login email is required.").email("Invalid email address."),
});

const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
    marginLeft: "auto",
    marginRight: "auto",
  },
  title: {
    marginBottom: theme.spacing(3),
  }
}));

export default function ForgetPassword(props) {
  const classes = useStyles();
  const history = useHistory();
  const { resetPassword } = useContext(AuthContext);
  const [error, setError] = useState("");
  const [open, setOpen] = useState(true);

  // const initialValues = useSelector((state) => state.user);

  const handleSubmit = async (values, { setSubmitting, setFieldError }) => {
    try {
      const { email } = values;
      await resetPassword(email);
    } catch (error) {
      setOpen(true);
      setError(error.message);
    }

    // dispatch(saveUserInfo(values));
    // setActiveStep(activeStep + 1)
  }

  return (
    <UserFrame>

      <div style={{ padding: "20px", }}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography variant="h4" component="h1" align="center" className={classes.title}>Forget Password</Typography>
        {error &&
          <Collapse in={open}>
            <Box marginBottom={3}>
              <Alert severity="error" action={<IconButton color="inherit" size="small" onClick={() => { setOpen(false); }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>}>
                {error}
              </Alert>
            </Box>
          </Collapse>
        }
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
          {() => (
            <Form autoComplete="on" noValidate>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <BcTextField label="Login Email" name="email" required autoComplete="email" autoFocus />
                </Grid>
                <Grid item container justify="space-between" >
                  <Button color="primary" variant="outlined" onClick={() => history.push("/signup")}>Signup</Button>
                  <Button color="primary" variant="contained" type="submit">Reset Password</Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </div>

    </UserFrame>
  );
}