import { Grid } from '@material-ui/core';
import { Form, Formik } from 'formik';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from "yup";
import { BcTextField } from '../common/BcComponents';
import SubmitButton from '../common/SubmitButton';
import { saveAccountInfo } from '../store/userSlice';


const validationSchema = yup.object({
  email: yup.string().required("Email is required.").email("Invalid email address."),
  // .test("checkUsername", "Alreay exists", 
  // (value, context) => {
  //   return value === undefined ? true : value.toLowerCase() !== "BillChenGreat@gmail.com".toLowerCase()
  // }).required("Email is required."),
  password: yup.string().required("Password is required.").min(8, "Password must contain 8 or more characters."),
  // password: yup.string().required("Password is required.").matches(
  //   /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
  //   "Password must contain 8+ Characters, one uppercase, one lowercase, one number and one special case Character."
  // ),
  confirmPassword: yup.string().required("Confirm password is required.").oneOf([yup.ref('password'), null], 'Passwords must match'),
});

export default function AccountInfo({ activeStep, setActiveStep }) {
  const dispatch = useDispatch();
  const initialValues = useSelector(state => state.user); // selectUser();

  const handleSubmit = async (values, { setFieldError, setSubmitting }) => {
    setSubmitting(true);

    // const isUserExisted = await hasUsernameExisted(values.email)
    // if (isUserExisted) {
    //   setFieldError("email", "The email already exists in our system.");
    //   setSubmitting(false)
    //   return;
    // }

    dispatch(saveAccountInfo(values));
    setActiveStep(activeStep + 1);
    // setSubmitting(false);
  }

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {({ isSubmitting }) => (
        <Form autoComplete="on" noValidate>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <BcTextField label="Login Email" name="email" required autoComplete="email" />
            </Grid>

            <Grid item xs={12}>
              <BcTextField label="Password" name="password" required autoComplete="new-password" type="password" />
              {/* <BcTextField label="Password" name="password" required autoComplete="new-password" /> */}
            </Grid>

            <Grid item xs={12}>
              <BcTextField label="Confirm Password" name="confirmPassword" required autoComplete="new-password" type="password" />
              {/* <BcTextField label="Confirm Password" name="confirmPassword" required autoComplete="new-password" /> */}
            </Grid>
          </Grid>

          <SubmitButton showBackButton={false} activeStep={activeStep} setActiveStep={setActiveStep} isSubmitting={isSubmitting} />

        </Form>
      )}
    </Formik>
  );
}