import { Button, Grid, MenuItem, Paper, Typography, Divider, IconButton, InputAdornment, } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import firebase from 'firebase/app';
import { Form, Formik, FieldArray, } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as yup from "yup";
import saveCustomerAddress from '../businessLogic/saveCustomerAddress';
import { Carriers, sleep } from "../businessLogic/utility";
import { BcRadio, BcTextField } from '../common/BcComponents';
import Loading from "../common/Loading";
import NoticeBar from '../common/NoticeBar';
import { AuthContext } from "../firebase/AuthContext";
import { savePackageTypeDhl, savePackageTypeFedex, savePackageTypePurolator, saveShippingInfo } from '../store/shippingSlice';
import Contact from "./Contact";
import { AddCircleOutline, RemoveCircleOutline } from '@material-ui/icons';
import { Link } from 'react-router-dom';

const shipper = {
  title: "Ship From",
  nameLabel: "Shipper Name",
  name: "shipperName",
  addressSaved: "shipperAddressSaved",
  address1: "shipperAddress1",
  address2: "shipperAddress2",
  country: "shipperCountry",
  province: "shipperProvince",
  city: "shipperCity",
  postalCode: "shipperPostalCode",
  phone: "shipperPhone",
  email: "shipperEmail",
  shipDate: "shipDate",
  instructions: "shipperInstructions",
  confirm: "confirmDelivery",
};

const receiver = {
  title: "Ship To",
  nameLabel: "Receive Name",
  name: "receiverName",
  addressSaved: "receiverAddressSaved",
  address1: "receiverAddress1",
  address2: "receiverAddress2",
  country: "receiverCountry",
  province: "receiverProvince",
  city: "receiverCity",
  postalCode: "receiverPostalCode",
  phone: "receiverPhone",
  email: "receiverEmail",
  instructions: "receiverInstructions",
  confirm: "signatureRequired",
};
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const validationSchema = yup.object({
  shipperName: yup.string().required("Name is required."),
  shipperAddress1: yup.string().required("Address 1 is required."),
  // shipperAddress2: yup.string().required("Address 2 is required."),
  shipperCity: yup.string().required("City is required."),
  shipperPostalCode: yup.string().required("Postal code is required."),
  shipperCountry: yup.string().required("Country is required."),
  shipperProvince: yup.string().required("Province is required."),
  shipperPhone: yup.string().matches(phoneRegExp, 'Phone number is not valid').max(11, "Phone number must be less than or equal to 10").required("Phone number is required."),
  shipperEmail: yup.string().email("Invalid email address.").required("Email is required."),

  receiverName: yup.string().required("Name is required."),
  receiverAddress1: yup.string().required("Address 1 is required."),
  // receiverAddress2: yup.string().required("Address 2 is required."),
  receiverCity: yup.string().required("City is required."),
  receiverPostalCode: yup.string().required("Postal code is required."),
  receiverCountry: yup.string().required("Country is required."),
  receiverProvince: yup.string().required("Province is required."),
  receiverPhone: yup.string().matches(phoneRegExp, 'Phone number is not valid').max(11, "Phone number must be less than or equal to 10").required("Phone number is required."),
  receiverEmail: yup.string().email("Invalid email address.").required("Email is required."),
  customerPackageLength: yup.number().when(
    'packageType', {
    is: 'customer',
    then: yup.number().min(0, "Length should greater than 0.").required("Length is required.")
  }),
  customerPackageWidth: yup.number().when(
    'packageType', {
    is: 'customer',
    then: yup.number().min(0, "Width should greater than 0.").required("Width is required.")
  }),
  customerPackageHeight: yup.number().when(
    'packageType', {
    is: 'customer',
    then: yup.number().min(0, "Height should greater than 0.").required("Height is required.")
  }),
  customerPackageWeight: yup.number().when(
    'packageType', {
    is: 'customer',
    then: yup.number().min(0, "Weight should greater than 0.").required("Weight is required.")
  }),
  carrierPackageWeight: yup.number().when(
    'packageType', {
    is: 'carrier',
    then: yup.number().min(0, "Weight should greater than 0.").required("Weight is required.")
  }),
  packageTypePurolator: yup.string().when(
    'packageType', {
    is: 'carrier',
    then: yup.string().required("Package type is required.")
  }),
  packageTypeFedex: yup.string().when(
    'packageType', {
    is: 'carrier',
    then: yup.string().required("Package type is required.")
  }),
  packageTypeDhl: yup.string().when(['shipperCountry', 'receiverCountry', 'packageType'], {
    is: (shipperCountry, receiverCountry, packageType) => shipperCountry !== receiverCountry && packageType === "carrier",
    then: yup.string().required("Package type is required."),
  }),
  contents: yup.string().when(['shipperCountry', 'receiverCountry'], {
    is: (shipperCountry, receiverCountry) => shipperCountry !== receiverCountry,
    then: yup.string().required("Contents is required."),
  }),
  numberOfPackage: yup.number().when(['shipperCountry', 'receiverCountry'], {
    is: (shipperCountry, receiverCountry) => shipperCountry !== receiverCountry,
    then: yup.number().integer("Value must be an integer.").min(1, "Minimum is 1"),
  }),
  non_delivery: yup.string().when(['shipperCountry', 'receiverCountry'], {
    is: (shipperCountry, receiverCountry) => shipperCountry !== receiverCountry,
    then: yup.string().required("Non Delivery is required."),
  }),
  customs_items: yup.array().when(['shipperCountry', 'receiverCountry'], {
    is: (shipperCountry, receiverCountry) => shipperCountry !== receiverCountry,
    then: yup.array().of(
      yup.object().shape({
        description: yup.string().required("Description is required."),
        harmonized_tariff_code: yup.string().required("Code can't be empty."),
        quantity: yup.number().integer("Must be integer.").min(0, "Minimum is 0"),
        value: yup.number().min(0, "Minimum is 0"),
      })
    ),
  }),
});

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(2),
    padding: theme.spacing(1.4),
    // border: "1px solid red",
  },
  button: {
    margin: theme.spacing(1, 2, 3, 1),
  },
  carrier: {
    marginRight: theme.spacing(2),
    minWidth: "200px",
  },
  carrierTitle: {
    marginBottom: theme.spacing(3),
  }
}));



export default function FillInfo({ activeStep, setActiveStep }) {

  const dispatch = useDispatch();
  const shippingState = useSelector((state) => state.shipping);
  const { currentUser } = useContext(AuthContext);

  const classes = useStyles();
  const [notice, setNotice] = useState({ message: "", open: false, severity: "error" });
  const [isLoading, setIsLoading] = useState(true);

  function processCarriers(rawCarriers) {
    // const rawData = UseTestData ? __carriers : rawCarriers;
    // const dispatch = useDispatch();
    const carriersData = rawCarriers.data.data.carriers;

    for (const carrier of carriersData) {
      switch (carrier.carrier_id) {
        case Carriers.FedEx.id:
          dispatch(savePackageTypeFedex(carrier.packages));
          break;
        case Carriers.DHL.id:
          dispatch(savePackageTypeDhl(carrier.packages));
          break;

        case Carriers.Purolator.id:
        default:
          dispatch(savePackageTypePurolator(carrier.packages));
          break;
      }
    }
  }


  useEffect(() => {
    async function fetchData() {
      // if carriers has no data.
      if (shippingState.carrierPackagesFedex.length <= 0 && shippingState.carrierPackagesPurolator.length <= 0) {
        try {
          const getCarriers = firebase.functions().httpsCallable('getCarriers');

          const rawCarriers = await getCarriers();
          if (rawCarriers?.data?.status !== 200) {
            console.log(rawCarriers);
            throw new Error("Error when loading carriers. Detail: " + rawCarriers.data.data.errors[0].message);
          }

          // if (rawCarriers && rawCarriers.data && rawCarriers.data.status !== 200) {
          //   console.log(rawCarriers);
          //   //here save data to Firebase analytics.
          //   throw new Error("Something wrong when loading data! Please contact site admin.");
          // }
          // console.log(JSON.stringify(rawCarriers));
          processCarriers(rawCarriers);

        } catch (error) {
          // toast.error(error.message);
          setNotice({ ...notice, open: true, message: error.message });
          console.error(error);
        }
      }
    }

    fetchData()
    setIsLoading(false);

  }, [])

  const handleSubmit = async (values) => {

    try {
      dispatch(saveShippingInfo(values));
      await saveCustomerAddress(values, currentUser);
      setActiveStep(activeStep + 1);
    } catch (error) {
      // toast.error(error.message);
      console.log(error);
      setNotice({ ...notice, open: true, message: error.message });
    }

  };

  if (isLoading) return (<Loading />);

  return (
    <Formik initialValues={shippingState} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {(props) => (
        <Form autoComplete="on" noValidate>
          <NoticeBar notice={notice} setNotice={setNotice} />

          <Grid container justify="space-evenly">
            {/* Ship From */}
            <Grid item xs={12} md={6}>
              <Paper className={classes.paper} variant="outlined">
                <Contact key="shipper" person={shipper} {...props} />
              </Paper>
            </Grid>

            {/* End Ship From */}

            {/* Ship To */}
            <Grid item xs={12} md={6}>
              <Paper className={classes.paper} variant="outlined">
                <Contact key="receiver" person={receiver} {...props} />
              </Paper>
            </Grid>
            {/* End Ship To */}

            {/* Package */}
            <Grid item xs={12}>
              <Paper className={classes.paper} variant="outlined">
                <Typography variant="h5" gutterBottom>Packaging</Typography>
                {/* <RadioGroup row > */}
                <BcRadio label="Envelope / Carrier Package" name="packageType" value="carrier" type="radio" />
                <BcRadio label="Customer Package" name="packageType" value="customer" type="radio" />
                {/* <BcRadio label="Carrier Package" name="packageType" value="carrierPackage" type="radio" /> */}
              </Paper>
            </Grid>

            {/* Carrier Package Dimentions */}
            {props.values.packageType === "carrier" &&
              <Grid item xs={12}>
                <Paper className={classes.paper} variant="outlined">
                  <Typography variant="h5" className={classes.carrierTitle}>Carrier Package (Type &amp; Weight)</Typography>
                  <Grid container spacing={2}>

                    <Grid item xs={12}>
                      <BcTextField select name="packageTypePurolator" label="Purolator" fullWidth={false} className={classes.carrier} >
                        {shippingState.carrierPackagesPurolator.map(type => (<MenuItem key={type.package_code} value={type.package_code}>{type.name}</MenuItem>))}
                      </BcTextField>

                      <BcTextField select name="packageTypeFedex" label="FedEx" fullWidth={false} className={classes.carrier} >
                        {shippingState.carrierPackagesFedex.map(type => (<MenuItem key={type.package_code} value={type.package_code}>{type.name}</MenuItem>))}
                      </BcTextField>

                      {props.values.shipperCountry !== props.values.receiverCountry &&
                        <BcTextField select name="packageTypeDhl" label="DHL" fullWidth={false} className={classes.carrier} >
                          {shippingState.carrierPackagesDhl.map(type => (<MenuItem key={type.package_code} value={type.package_code}>{type.name}</MenuItem>))}
                        </BcTextField>
                      }
                      {/* <FormControl variant="outlined" size="small" className={classes.carrier}  >
                        <InputLabel id="PurolatorLabel">Purolator</InputLabel>
                        <Select native labelId="PurolatorLabel"
                          helperText={props.touched.packageTypePurolator ? props.errors.packageTypePurolator : "rrr"}
                          error={props.touched.packageTypePurolator && Boolean(props.errors.packageTypePurolator)}
                          onChange={props.handleChange("packageTypePurolator")} name="packageTypePurolator" label="Purolator" value={props.values.packageTypePurolator}>
                          {//shippingValues.carrierPackagesPurolator.map(type => (<MenuItem key={type.package_code} value={type.package_code}>{type.name}</MenuItem>))
                            shippingValues.carrierPackagesPurolator.map(type => (<option key={type.package_code} value={type.package_code}>{type.name}</option>))
                          }
                        </Select>
                      </FormControl> */}

                      {/* <FormControl variant="outlined" size="small" className={classes.carrier}  >
                        <InputLabel id="FedExLabel">FedEx</InputLabel>
                        <Select labelId="FedExLabel" onChange={props.handleChange} name="packageTypeFedex" label="FedEx" value={props.values.packageTypeFedex}>
                          {shippingValues.carrierPackagesFedex.map(type => (<MenuItem key={type.package_code} value={type.package_code}>{type.name}</MenuItem>))}
                        </Select>
                      </FormControl>

                      <FormControl variant="outlined" size="small" className={classes.carrier} >
                        <InputLabel id="DHLLabel">DHL</InputLabel>
                        <Select labelId="DHLLabel" onChange={props.handleChange} name="packageTypeDhl" label="DHL" value={props.values.packageTypeDhl}>
                          {shippingValues.carrierPackagesDhl.map(type => (<MenuItem key={type.package_code} value={type.package_code}>{type.name}</MenuItem>))}
                        </Select>
                      </FormControl> */}

                      {/* {shippingValues.shipperCountry !== shippingValues.receiverCountry &&
                        <FormControl variant="outlined" size="small" className={classes.carrier} >
                          <InputLabel id="DHLLabel">DHL</InputLabel>
                          <Select labelId="DHLLabel" onChange={props.handleChange} name="packageTypeDhl" label="DHL" value={props.values.packageTypeDhl}>
                            {shippingValues.carrierPackagesDhl.map(type => (<MenuItem key={type.package_code} value={type.package_code}>{type.name}</MenuItem>))}
                          </Select>
                        </FormControl>
                      } */}
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <BcTextField label="Weight (lbs)" name="carrierPackageWeight" required type="number" />
                    </Grid>

                    <Grid item xs={12} md={8}>
                      <BcTextField label="Description" name="carrierPackageDesc" />
                    </Grid>

                    <Grid item >
                      <Alert severity="info">Price will be charged based on actual size.</Alert>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            }

            {/* Customer Package Dimentions */}
            {props.values.packageType === "customer" &&
              <Grid item xs={12}>
                <Paper className={classes.paper} variant="outlined">
                  <Typography variant="h5" className={classes.carrierTitle}>Customer Package (Dimentions &amp; Weight)</Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                      <BcTextField label="Length (in)" name="customerPackageLength" required type="number" />
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <BcTextField label="Width (in)" name="customerPackageWidth" required type="number" />
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <BcTextField label="Height (in)" name="customerPackageHeight" required type="number" />
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <BcTextField label="Weight (lbs)" name="customerPackageWeight" required type="number" />
                    </Grid>

                    <Grid item xs={12} md={8}>
                      <BcTextField label="Description" name="customerPackageDesc" />
                    </Grid>

                    <Grid item >
                      <Alert severity="info">Price will be charged based on actual size.</Alert>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            }

            {props.values.receiverCountry !== props.values.shipperCountry &&
              <Paper className={classes.paper} variant="outlined" elevation={0}>
                <Typography variant="h5" gutterBottom>International Shipping</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <BcTextField select name="contents" label="Contents" required >
                      <MenuItem value="gift">Gift</MenuItem>
                      <MenuItem value="merchandis">Merchandis</MenuItem>
                      <MenuItem value="returnedGoods">Returned Goods</MenuItem>
                      <MenuItem value="documents">Documents</MenuItem>
                      <MenuItem value="sample">Sample</MenuItem>
                      <MenuItem value="other">Other</MenuItem>
                    </BcTextField>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <BcTextField select name="non_delivery" label="Non-Delivery" required >
                      <MenuItem value="treat_as_abandoned">Treat as abandoned</MenuItem>
                      <MenuItem value="return_to_sender">Return to sender</MenuItem>
                    </BcTextField>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <BcTextField select name="taxable_entity_type" label="Taxable Entity" >
                      <MenuItem value="shipper">Shipper</MenuItem>
                      <MenuItem value="recipient">Recipient</MenuItem>
                    </BcTextField>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <BcTextField label="Tax Issuing Authority" name="issuing_authority" />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <BcTextField select name="identifier_type" label="Taxable Entity Id Type" >
                      <MenuItem value="vat">Vat</MenuItem>
                      <MenuItem value="eori">Eori</MenuItem>
                      <MenuItem value="ssn">SSN</MenuItem>
                      <MenuItem value="ein">EIN</MenuItem>
                      <MenuItem value="tin">TIN</MenuItem>
                    </BcTextField>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <BcTextField label="Taxable Entity ID" name="value" />
                  </Grid>

                  <Grid item xs={12}>
                    <Divider />
                  </Grid>

                  <Grid item xs={6}>
                    <BcTextField label="Number of Item in the package" name="numberOfPackage" required type="number" />
                  </Grid>

                  <Grid item xs={6}>

                  </Grid>

                  <FieldArray name="customs_items">
                    {(arrayHelpers) =>
                      props.values.customs_items.map((item, index) => (
                        <React.Fragment key={index}>
                          <Grid item xs={4} md={3} >
                            <BcTextField label="Description" name={`customs_items.${index}.description`} required />
                          </Grid>

                          <Grid item xs={4} md={1} >
                            <BcTextField label="Quantity" name={`customs_items.${index}.quantity`} type="number" />
                          </Grid>

                          <Grid item xs={4} md={2}>
                            <BcTextField label="Value" type="number" name={`customs_items.${index}.value`} InputProps={{ startAdornment: (<InputAdornment position="start">$</InputAdornment>), }} />
                          </Grid>

                          <Grid item xs={2} md={3} >
                            <BcTextField label="Harmonized Tariff Code" name={`customs_items.${index}.harmonized_tariff_code`} required />
                          </Grid>

                          <Grid item xs={2} md={2}>
                            <BcTextField label="SKU" name={`customs_items.${index}.sku`} />
                          </Grid>

                          <Grid item xs={2} md={1}>
                            <div style={{ display: "flex" }}>
                              <IconButton color="primary" onClick={() => arrayHelpers.push({ description: "", quantity: 1, value: 0, harmonized_tariff_code: "", country_of_origin: "", unit_of_measure: "", sku: "" })}>
                                <AddCircleOutline />
                              </IconButton>
                              <IconButton color="primary" onClick={() => arrayHelpers.remove(index)}>
                                <RemoveCircleOutline />
                              </IconButton>
                            </div>
                          </Grid>
                        </React.Fragment>
                      )
                      )}

                  </FieldArray>


                </Grid>

              </Paper>

            }


          </Grid>

          {/* <SubmitButton showBackButton={false} activeStep={activeStep} setActiveStep={setActiveStep} /> */}

          <Grid container justify="flex-end">
            {/* <Button variant="outlined" color="primary" className={classes.button}>Back</Button> */}
            <Button variant="contained" color="primary" className={classes.button} type="submit">Next</Button>
          </Grid>

          {/* <h5>My data:</h5>
          <pre>{JSON.stringify(props.values, null, 4)}</pre> */}

          {/* <h5>Data validation</h5>
            <pre>{JSON.stringify(props.errors, null, 4)}</pre> */}

        </Form>
      )}
    </Formik>


  );
}