import { Avatar, Button, CircularProgress, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { Form, Formik } from 'formik';
import React, { useContext, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from "yup";
import { BcTextField } from '../common/BcComponents';
import NoticeBar from '../common/NoticeBar';
import { AuthContext } from '../firebase/AuthContext';
import UserFrame from './UserFrame';

const initialValues = {
  email: "",
  password: "",
};

const validationSchema = yup.object({
  email: yup.string().required("Login email is required.").email("Invalid email address."),
  password: yup.string().required("Password is required."), //.min(8, "Invalid password."),
});

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: theme.palette.primary.main,
    width: '1.7rem',
    height: '1.7rem',
    marginRight: '0.5rem',
  },
  title: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: theme.spacing(3),
    borderRadius: "12px 12px 0 0",
    fontWeight: 600,
  },
  span: {
    borderBottomWidth: "3px",
    borderBottomStyle: "solid",
    borderColor: theme.palette.secondary.main,
  },
  subtitle: {
    display: "flex",
    alignItems: "center",
  },
  grid: {
    padding: theme.spacing(3),
  },

  loginButton: {
    padding: theme.spacing(1.5, 4),
  },
  progressButton: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    margin: "auto",
  }
}));

export default function Login(props) {
  const classes = useStyles();
  const history = useHistory();
  const { login } = useContext(AuthContext);
  const [notice, setNotice] = useState({ message: "", open: false, severity: "error" });
  const [processing, setProcesssing] = useState(false);

  const handleSubmit = async (values, { setSubmitting }) => {
    // setSubmitting(true);
    // timer.current = setTimeout(() => { setSubmitting(false) }, 10000);
    setProcesssing(true);
    // timer.current = setTimeout(() => { setProcesssing(false) }, 1000);
    try {
      const { email, password } = values;
      await login(email, password);
      // setNotice({ ...notice, open: true, message: "dddddddddddddddddddddddddddddd" });
      history.push("/shipping");
    } catch (error) {

      // toast.error(error.message);
      // setSubmitting(false);
      setProcesssing(false);
      setNotice({ ...notice, open: true, message: error.message });
    }
  }

  return (
    <UserFrame>

      <Typography variant="h5" component="h1" align="center" className={classes.title}><span className={classes.span}>Your Shipping</span> Partner</Typography>
      {/* <Divider style={{ width: "70%", color: "orange" }}  /> */}
      <NoticeBar notice={notice} setNotice={setNotice} />

      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ isSubmitting }) => (
          <Form autoComplete="off" noValidate>
            <Grid container spacing={3} className={classes.grid}>
              <Grid item xs={12} className={classes.subtitle}>
                <Avatar className={classes.avatar} sizes="1">
                  <LockOutlinedIcon />
                </Avatar>
                <Typography variant="h6">Sign in to continue</Typography>
              </Grid>

              <Grid item xs={12}>
                <BcTextField label="Login Email" name="email" required autoComplete="email" />
              </Grid>

              <Grid item xs={12}>
                <BcTextField label="Password" name="password" required autoComplete="current-password" type="password" />
              </Grid>


              <Grid item container justify="center" >
                <div style={{ position: "relative", }}>
                  <Button color="primary" variant="contained" type="submit" disabled={processing} className={classes.loginButton}>Login</Button>
                  {processing && <CircularProgress size={24} className={classes.progressButton} />}
                </div>
              </Grid>

              <Grid item xs={12} style={{ paddingBottom: 0, }}>
                <Grid container>
                  <Grid item xs={6}>
                    <Link to="/forget-password" variant="body2">Forgot password?</Link>
                  </Grid>

                  <Grid item xs={6} style={{ textAlign: "right", }}>
                    <Link to="/signup" variant="body2">No account? Sign up</Link>
                  </Grid>
                </Grid>

              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>

    </UserFrame>
  );
}