import { AppBar, Container, Paper, Toolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { Link } from "react-router-dom";
import Copyright from '../common/Copyright';
import MenuIcon from '@material-ui/icons/Menu';

const menuItems = [
  { title: 'Home', url: '#' },
  { title: 'About Us', url: '#' },
  { title: 'Franchising', url: '#' },
  { title: 'Find a Store', url: '#' },
  { title: 'Login', url: '/login' },
];

const useStyles = makeStyles((theme) => ({
  '@global': {
    html: {
      backgroundColor: theme.palette.primary.main,
    },
  },
  appBar: {
    backgroundColor: theme.palette.common.white,
    borderBottom: "2px solid grey",
  },
  main: {

    backgroundImage: 'url(/images/login_bg3.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    overflow: 'auto',

    height: "calc(100vh - 130px)",
    display: 'flex',
    alignItems: "center",
    justifyContent: "center",

  },
  // marginSpace: {
  //   margin: theme.spacing(3, 0, 3, 0),
  // },
  paper: {
    alignItems: "center",
    padding: 0,
    borderRadius: "12px",
    // margin: theme.spacing(3, 'auto', 3, 'auto'),
    // [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
    //   margin: theme.spacing(15, 'auto', 15, 'auto'),
    // },
  },
  nav: {
    textAlign: "right",
    flexGrow: 1,
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
  link: {
    textDecoration: "none",
    margin: theme.spacing(0, 3),
    display: "inline-block",
    color: theme.palette.text.primary,
    fontWeight: 600,
    '&:hover': {
      textDecoration: "none",
    },
    '&::after': {
      content: '""',
      borderBottom: "3px solid orange",
      display: 'block',
      width: "0",
      transition: "0.5s",
    },
    '&:hover::after': {
      width: "100%",
    },
  },
}));

export default function UserFrame({ children }) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <AppBar position="relative" elevation={0} className={classes.appBar}>
        <Toolbar>
          <img src="/logo.png" style={{ maxWidth: 200, height: 57, }} alt="logo" />
          {/* <MenuIcon color="primary" /> */}
          <nav className={classes.nav}>
            {menuItems.map(menuItem => (
              <Link key={menuItem.title} to={menuItem.url} className={classes.link} variant="body">{menuItem.title}</Link>
            ))}
          </nav>
        </Toolbar>
      </AppBar>

      <main className={classes.main}>
        <Container maxWidth="xs" component={Paper} className={classes.paper} >
          {children}
        </Container>
      </main>

      <Copyright />
    </React.Fragment>
  );
}