import { Step, StepLabel, Stepper, Typography, Avatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import AccountInfo from "./AccountInfo";
import Terms from './Terms';
import UserInfo from './UserInfo';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import UserFrame from './UserFrame';

const steps = ['Account Info', 'User Info', 'Terms'];

const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
    marginLeft: "auto",
    marginRight: "auto",
  },
  title: {
    marginBottom: theme.spacing(3),
  },
  stepper: {
    padding: theme.spacing(3, 0, 3),
  },
  stepperTitle: {
    marginBottom: theme.spacing(2),
  },
}));

export default function Signup() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);

  function getStepContent() {
    switch (activeStep) {
      case 0:
        return <AccountInfo activeStep={activeStep} setActiveStep={setActiveStep} />;
      case 1:
        return <UserInfo activeStep={activeStep} setActiveStep={setActiveStep} />;
      case 2:
        return <Terms activeStep={activeStep} setActiveStep={setActiveStep} />;
      default:
        throw new Error('Unknown step');
    }
  }

  return (
    <UserFrame>

      <div style={{ padding: "20px", }}>
        <Avatar className={classes.avatar}>
          <AccountCircleOutlinedIcon />
        </Avatar>
        <Typography variant="h4" component="h1" align="center" gutterBottom>User Register</Typography>
        <Stepper activeStep={activeStep} className={classes.stepper} >
          {steps.map(step => <Step key={step}><StepLabel>{step}</StepLabel></Step>)}
        </Stepper>
        <Typography variant="h6" className={classes.stepperTitle}>
          {steps[activeStep]}
        </Typography>
        {getStepContent(activeStep)}
      </div>


    </UserFrame>

  );
}