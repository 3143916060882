import { AppBar, Container, Divider, Drawer, Hidden, IconButton, List, ListItemIcon, ListItemText, Toolbar, Typography } from '@material-ui/core';
import MuiListItem from "@material-ui/core/ListItem";
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import MenuIcon from '@material-ui/icons/Menu';
import PageviewIcon from '@material-ui/icons/Pageview';
import React, { useState } from 'react';
import { Link, useLocation } from "react-router-dom";

const drawerWidth = 210;
const menuItems = [
  { text: "Shipping", link: "/shipping", icon: LocalShippingIcon, id: 1 },
  { text: "Tracking", link: "/tracking", icon: PageviewIcon, id: 2 },
  { text: "My Account", link: "/account", icon: AccountBoxIcon, id: 3 },
  { text: "Support", link: "/support", icon: ContactSupportIcon, id: 4 },
  { text: "Log out", link: "/logout", icon: ExitToAppIcon, id: 5 },
];

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },

  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      // backgroundColor: "#17558d",
    },
  },
  listItemIcon: {
    color: "inherit",
  },
  menuButton: {
    marginRight: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: theme.palette.primary.main,
    border: 0,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

function getCurrentMenu(pathname) {
  if (pathname === "/")
    return 1

  if (pathname.includes("/track/"))
    return 2

  return menuItems.find(menu => menu.link === pathname).id
}

export default function ShipEnvoy({ children }) {
  const { window } = children;
  const theme = useTheme();
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = useState(false);
  let { pathname } = useLocation();
  const selectedIndex = getCurrentMenu(pathname)

  const ListItem = withStyles({
    root: {
      "&$selected": {
        color: theme.palette.secondary.main,
        backgroundColor: theme.palette.primary.main,
        fontWeight: 700,
      },
      "&$selected:hover": {
        backgroundColor: theme.palette.secondary.main,
        color: "white"
      },
      "&:hover": {
        backgroundColor: theme.palette.secondary.main,
        color: "white"
      }
    },
    selected: {
    }
  })(MuiListItem);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div style={{ textAlign: 'center', color: "white", }} id="drawer">
      {/* <ExplicitIcon />  */}
      {/* <Typography component="h1" variant="h4" style={{ height: "62px", paddingTop: "10px", fontSize: "32px", fontWeight: 500, fontStyle: "italic", color: "#fa7847", }} >ShipEnvoy</Typography> */}
      <div style={{ borderBottom: "2px solid grey", height: "66px", backgroundColor: "white" }}>
        <img src="/logo.png" style={{ maxWidth: 200, height: 57 }} alt="logo" />
      </div>
      <Divider />
      <List>
        {menuItems.map((menuItem) => (
          <ListItem button key={menuItem.id} component={Link} to={menuItem.link}
            selected={selectedIndex === menuItem.id}
          >
            <ListItemIcon className={classes.listItemIcon} style={{ display: 'flex', justifyContent: 'center' }}>
              <menuItem.icon />
            </ListItemIcon>
            <ListItemText primary={menuItem.text} />
          </ListItem>
        ))}
      </List>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar} elevation={0} style={{ backgroundColor: "white", borderBottom: "2px solid grey" }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>

          <Container>
            <Typography variant="h6" noWrap align="center" color="primary">
              {pathname.slice(1).toUpperCase()}
            </Typography>
          </Container>

        </Toolbar>
      </AppBar>

      <nav className={classes.drawer} aria-label="mailbox folders"  >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css" >
          <Drawer
            container={container}
            variant="temporary"
            anchor="left"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer classes={{ paper: classes.drawerPaper, }} variant="permanent" open >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>

      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
    </div>
  );
}
