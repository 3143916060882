import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState, useContext } from 'react';
import ShipEnvoy from './ShipEnvoy';
import PropTypes from "prop-types";
import { Input, Button, Tabs, Tab, AppBar, Snackbar, TablePagination, CircularProgress, IconButton, TextField, Typography, Paper, Grid, TableCell, TableRow, TableContainer, Table, TableHead, TableBody, TableSortLabel } from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import MuiAlert from '@material-ui/lab/Alert';
import DateFnsUtils from '@date-io/date-fns';
import { Link } from 'react-router-dom'
import SearchIcon from '@material-ui/icons/Search';
import PrintIcon from '@material-ui/icons/Print';
import DescriptionIcon from '@material-ui/icons/Description';
import { firestore } from "../firebase/config"
import { AuthContext } from "../firebase/AuthContext"





function createData(date, tracking, carrier, receiver, phone, destination, status, details, download) {

  return { date, tracking, carrier, receiver, phone, destination, status, details, download };
}

const createArray = (doc, rows) => {
  let ref = doc.data()
  let date = ref.created_date ? ref.created_date.toDate().toISOString().slice(0, 10) : ""
  let tracking = ref.service_info.tracking_number ? ref.service_info.tracking_number : ""
  let carrier = ref.service_info.carrier_code ? ref.service_info.carrier_code : ""
  let receiver = ref.receiver_name ? ref.receiver_name : ""
  let phone = ref.receiver_phone ? ref.receiver_phone : ""
  let address1 = ref.receiver_address1 ? ref.receiver_address1 : ""
  let address2 = ref.receiver_address1 ? ref.receiver_address2 : ""
  let city = ref.receiver_city ? ref.receiver_city : ""
  let country = ref.receiver_country ? ref.receiver_country : ""
  let postalCode = ref.receiver_postal_code ? ref.receiver_postal_code : ""
  let destination = `${address2}-${address1} ${city} ${country} ${postalCode}`
  let status = ref.service_info.tracking_status ? ref.service_info.tracking_status.replace("_", " ").toUpperCase() : ""
  let details = ""
  let download = ref.service_info && ref.service_info.label_download && ref.service_info.label_download.pdf ? ref.service_info.label_download.pdf : ""
  rows.push(createData(date, tracking, carrier, receiver, phone, destination, status, details, download))
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: "date", numeric: false, disablePadding: false, label: "Ship Date" },
  { id: "tracking", numeric: false, disablePadding: false, label: "Tracking number" },
  { id: "receiver", numeric: false, disablePadding: false, label: "Receiver Name" },
  { id: "phone", numeric: false, disablePadding: false, label: "Phone" },
  { id: "destination", numeric: false, disablePadding: false, label: "Destination" },
  { id: "status", numeric: false, disablePadding: false, label: "Status" },
  { id: "details", numeric: false, disablePadding: false, label: "Details" },
  { id: "download", numeric: false, disablePadding: false, label: "Download Label" },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead style={{}}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ backgroundColor: "rgb(128,128,128, 0.2)" }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired
};

const objectToCsv = data => {
  const csvRows = []
  const headers = Object.keys(data[0])

  csvRows.push(["Ship date", "Tracking number", "Receiver name", "Phone", "Destination", "Status", "Details", "Download Label"])
  for (const row of data) {
    const values = headers.map(header => {
      const escaped = `"${row[header]}"`
      return escaped
    })
    csvRows.push(values.join(','))
  }
  return csvRows.join('\n')
}

const download = data => {

  const blob = new Blob([data], { type: 'text/csv' })
  const url = window.URL.createObjectURL(blob)
  const link = document.createElement("a");

  link.href = url
  link.download = "Tracking Table.csv"
  link.click()
}
const createCsvURL = data => {
  return download(objectToCsv(data))
}
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    position: "relative",
    margin: "0",
    padding: "0",
    boxSizing: "borderBox",

  },
  paper: {
    // margin: theme.spacing(2),
    // padding: theme.spacing(1.4),

  },
  downloadButton: {

  },
  table: {
    minWidth: 1200,

  },
  tableContainer: {
    overflowX: "hidden"
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },

  searchBar: {
    width: "600px"
  },
  input: {
    paddingLeft: "10px",
  },
  datePicker: {

  },
  link: {
    color: `${theme.palette.primary.main}`
  }
}));


export default function Tracking() {
  const classes = useStyles();

  const date = new Date();
  const firstDayOfTheMonth = new Date(date.getFullYear(), date.getMonth(), 1);
  const lastDayOfTheMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);


  const { currentUser } = useContext(AuthContext);
  const uid = currentUser ? currentUser.uid : sessionStorage.getItem("uid")
  const [isLoading, setLoading] = useState(true)
  const [data, setData] = useState([])

  const [tab, setTab] = useState(0)
  const [searchValue, setSearchValue] = useState("")
  const [startDate, setStartDate] = useState(firstDayOfTheMonth)
  const [endDate, setEndDate] = useState(lastDayOfTheMonth)
  const [triggerSearch, setTriggerSearch] = useState(1)



  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("tracking");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [openSnacFail, setOpenSnaFail] = useState(false);
  const [snackMessage, setSnackMessage] = useState("")

  useEffect(() => {
    setLoading(true)
    setPage(0)
    let rows = []
    const docRef = firestore.collection("Shipping").where("customer_id", "==", uid)
    docRef.get().then(function (querySnapshot) {
      if (!querySnapshot.empty && searchValue.length !== 0) {
        // search engine
        switch (tab) {
          case 0:
            firestore.collection("Shipping").where("service_info.tracking_number", "==", searchValue.trim()).get().then(docs => {
              docs.forEach(ref => {
                if (ref.exists) {
                  createArray(ref, rows)
                }
              })
              setData(rows)
              setLoading(false)
            }).catch(err => {
              console.log(err)
              setSnackMessage("Something went wrong")
              setOpenSnaFail(true)
              setLoading(false)
            })
            break
          case 1:
            docRef.where("receiver_phone", "==", searchValue.trim()).get().then(docs => {
              docs.forEach(ref => {
                createArray(ref, rows)
              })
              setData(rows)
              setLoading(false)
            }).catch(err => {
              console.log(err)
              setSnackMessage("Something went wrong")
              setOpenSnaFail(true)
              setLoading(false)
            });
            break
          default:
            break
        }
      } else if (tab === 2) {

        docRef.where("created_date", ">=", startDate).where("created_date", "<=", endDate).get().then(docs => {
          docs.forEach(ref => {
            createArray(ref, rows)
          })
          setData(rows)
          setLoading(false)
        }).catch(err => {
          console.log(err)
          setOpenSnaFail(true)
          setLoading(false)
        });
      }
      else {
        docRef.get().then(docs => {
          docs.forEach(ref => {
            createArray(ref, rows)
          })
          setData(rows)
          setLoading(false)
        }).catch(err => {
          console.log(err)
          setSnackMessage("Something went wrong")
          setOpenSnaFail(true)
          setLoading(false)
        });
        setLoading(false)
      }
    }).catch(err => {
      console.log(err)
      setSnackMessage("Something went wrong")
      setLoading(false)
    })

  }, [triggerSearch])

  const handleOnSearch = () => {
    setTriggerSearch(triggerSearch + 1)
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const snackFail = () => {
    setOpenSnaFail(false)
  }
  const handleTabOnChange = (event, newValue) => {
    if (newValue === 2) {
      setTriggerSearch(triggerSearch + 1)
    }
    setSearchValue("")
    setTab(newValue);
  };

  const handleSearchValue = e => {
    setSearchValue(e.target.value)
  }

  const handleStartDate = e => {
    setStartDate(e)
    setTriggerSearch(triggerSearch + 1)

  }
  const handleEndDate = e => {
    setEndDate(e)
    setTriggerSearch(triggerSearch + 1)
  }

  const downloadCSV = e => {
    createCsvURL(data)
  }
  const resetTable = () => {
    setSearchValue("")
    setStartDate(firstDayOfTheMonth)
    setEndDate(lastDayOfTheMonth)
    setTriggerSearch(triggerSearch + 1)
  }
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

  if (isLoading) {
    return (
      <ShipEnvoy>
        <Grid container className={classes.root} direction="column" justify="center" alignItems="center" spacing={4} >

          {/* ////////////search bar///////////////// */}
          <Grid item xs={12}  >
            <Grid item style={{ marginBottom: "5px" }}>
              <Paper >
                <AppBar position="static" >
                  <Tabs
                    value={tab}
                    onChange={handleTabOnChange}
                    centered
                  >
                    <Tab label="tracking number" />
                    <Tab label="phone" />
                    <Tab label="date" />
                  </Tabs>
                </AppBar>
              </Paper>
            </Grid>

            <Grid item className={classes.searchBar}>
              <Paper>
                <Input
                  className={classes.input}
                  fullWidth
                  placeholder={`Search by ${tab === 0 ? "tracking number" : "phone"}`}
                  style={{ display: tab === 2 ? "none" : "" }}
                  onChange={handleSearchValue}
                  value={searchValue}
                  endAdornment={<IconButton
                    type="submit"
                    onClick={handleOnSearch}
                    aria-label="search"
                  >
                    <SearchIcon />
                  </IconButton>}
                />
                <Grid item style={{ display: tab === 2 ? "" : "none" }} >
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container direction="row" justify="space-around" alignItems="center" >
                      <Grid item >
                        <KeyboardDatePicker
                          className={classes.datePicker}
                          disableToolbar
                          variant="inline"
                          format="yyyy-MM-dd"
                          margin="normal"
                          label="Start Date"
                          onChange={handleStartDate}
                          value={startDate}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                          autoOk
                        />
                      </Grid>
                      <Grid item >
                        <KeyboardDatePicker
                          className={classes.datePicker}
                          disableToolbar
                          variant="inline"
                          format="yyyy-MM-dd"
                          margin="normal"
                          label="End Date"
                          onChange={handleEndDate}
                          value={endDate}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                          autoOk
                        />
                      </Grid>
                    </Grid>
                  </MuiPickersUtilsProvider>
                </Grid>


              </Paper>
            </Grid>
          </Grid>


          {/* //////////////table start here////////////// */}
          <Grid item container xs={12} justify="center" alignItems="center" style={{ minHeight: "450px" }} spacing={1}>
            <CircularProgress />

          </Grid>
          <Snackbar open={openSnacFail} autoHideDuration={6000} onClose={snackFail}>
            <MuiAlert onClose={snackFail} elevation={6} variant="filled" severity="error">
              {snackMessage}
            </MuiAlert>
          </Snackbar>
        </Grid>
      </ShipEnvoy >
    )
  } else {
    return (
      <ShipEnvoy>
        <Grid container className={classes.root} direction="column" justify="center" alignItems="center" spacing={4} >

          {/* ////////////search bar///////////////// */}
          <Grid item xs={12}  >
            <Grid item style={{ marginBottom: "5px" }}>
              <Paper >
                <AppBar position="static" >
                  <Tabs
                    value={tab}
                    onChange={handleTabOnChange}
                    centered
                  >
                    <Tab label="tracking number" />
                    <Tab label="phone" />
                    <Tab label="date" />
                  </Tabs>
                </AppBar>
              </Paper>
            </Grid>

            <Grid item className={classes.searchBar}>
              <Paper>
                <Input
                  className={classes.input}
                  fullWidth
                  placeholder={`Search by ${tab === 0 ? "tracking number" : "phone"}`}
                  style={{ display: tab === 2 ? "none" : "" }}
                  onChange={handleSearchValue}
                  value={searchValue}
                  endAdornment={<IconButton
                    type="submit"
                    onClick={handleOnSearch}
                    aria-label="search"
                  >
                    <SearchIcon />
                  </IconButton>}
                />
                <Grid item style={{ display: tab === 2 ? "" : "none" }} >
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container direction="row" justify="space-around" alignItems="center" >
                      <Grid item >
                        <KeyboardDatePicker
                          className={classes.datePicker}
                          disableToolbar
                          variant="inline"
                          format="yyyy-MM-dd"
                          margin="normal"
                          label="Start Date"
                          onChange={handleStartDate}
                          value={startDate}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                          autoOk
                        />
                      </Grid>
                      <Grid item >
                        <KeyboardDatePicker
                          className={classes.datePicker}
                          disableToolbar
                          variant="inline"
                          format="yyyy-MM-dd"
                          margin="normal"
                          label="End Date"
                          onChange={handleEndDate}
                          value={endDate}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                          autoOk
                        />
                      </Grid>
                    </Grid>
                  </MuiPickersUtilsProvider>
                </Grid>


              </Paper>
            </Grid>
          </Grid>


          {/* //////////////table start here////////////// */}
          <Grid item container xs={12} justify="center" spacing={1} >
            <Grid item container justify="flex-end" xs={12} spacing={1}>
              <Grid item>
                <Button className={classes.downloadButton} variant="contained" color="primary" size="small" onClick={downloadCSV} disabled={data.length === 0 ? true : false} >
                  Download as CSV
              </Button>
              </Grid>
              <Grid item>
                <Button className={classes.downloadButton} variant="contained" color="primary" size="small" onClick={resetTable} >
                  reset table
              </Button>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.paper} variant="outlined" elevation={0} >
                <TableContainer className={classes.tableContainer} >
                  <Table
                    className={classes.table}
                    aria-labelledby="tracking list"
                    aria-label="tracking list"
                  >
                    <EnhancedTableHead
                      classes={classes}
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                      rowCount={data.length}

                    />
                    <TableBody>
                      {stableSort(data, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map(
                          (row, index) => {

                            return (
                              <TableRow key={index} style={{ backgroundColor: `${index % 2 !== 0 ? "rgb(128,128,128, 0.2)" : ""}` }}>
                                <TableCell >{row.date}</TableCell>
                                <TableCell >{row.tracking}</TableCell>
                                <TableCell >{row.receiver}</TableCell>
                                <TableCell >{row.phone}</TableCell>
                                <TableCell >{row.destination}</TableCell>
                                <TableCell >{row.status}</TableCell>
                                <TableCell align="center">
                                  <Link className={classes.link} to={`/track/${row.tracking}_${row.carrier}`} ><DescriptionIcon className={classes.icon} /></Link>
                                </TableCell>
                                <TableCell align="center">
                                  <Grid onClick={() => { window.open(row.download) }}  ><PrintIcon className={classes.icon} /></Grid>
                                </TableCell>
                              </TableRow>
                            );
                          }
                        )}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={data.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </Paper>
            </Grid>

          </Grid>
          <Snackbar open={openSnacFail} autoHideDuration={6000} onClose={snackFail}>
            <MuiAlert onClose={snackFail} elevation={6} variant="filled" severity="error">
              {snackMessage}
            </MuiAlert>
          </Snackbar>
        </Grid>
      </ShipEnvoy >
    );
  }
}

