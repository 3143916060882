import { createSlice } from '@reduxjs/toolkit';

const userInitialValues = {
  acceptTerms: true,
  confirmPassword: "",
  email: "",
  firstName: "",
  isLoading: false,
  lastName: "",
  password: "",
  phone: "",
  cardHolderName: "",
};

export const addressInitialValues = {
  address1: "",
  address2: "",
  country: "CA",
  province: "ON",
  city: "",
  postalCode: "",
};

const initialState = {
  ...userInitialValues,
  ...addressInitialValues,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    saveAccountInfo: (state, action) => {
      const { email, password, confirmPassword } = action.payload;
      state.email = email;
      state.password = password;
      state.confirmPassword = confirmPassword;
    },
    saveUserInfo: (state, action) => {
      const { firstName, lastName, phone, address1, address2, country, province, city, postalCode } = action.payload;
      state.firstName = firstName;
      state.lastName = lastName;
      state.address1 = address1;
      state.address2 = address2;
      state.city = city;
      state.postalCode = postalCode;
      state.phone = phone;
      state.country = country;
      state.province = province;
      state.cardHolderName = `${firstName} ${lastName}`;
    },
    saveCardHolderName: (state, action) => {
      const { cardHolderName } = action.payload;
      state.cardHolderName = cardHolderName;
    },
    getUserSuccess: (state, action) => {
      const { email } = action.payload;
      state.email = email;
      state.isLoading = false;
    },
    startLoading: (state, action) => {
      state.isLoading = true;
    },
  },

});

export const {
  saveAccountInfo,
  saveUserInfo,
  getUserSuccess,
  startLoading,
  saveCardHolderName,
} = userSlice.actions;

// export const selectUserFullName = (user) => `${user.firstName} ${user.lastName}`;

// export const selectUser = useSelector((state) => state.user);
// export const selectUser = state => state.user.email;

// export const fetchUser = (userId) => async dispatch => {
//   try {
//     // dispatch(getIssueStart())
//     await sleep(5000);
//     const user = await getUserById(userId);
//     dispatch(getUserSuccess(user));
//   } catch (error) {
//     throw error;
//     // dispatch(getIssueFailure(err.toString()))
//   }
// }

export default userSlice.reducer;