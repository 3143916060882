import { CircularProgress, Radio, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { lighten, makeStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import NoticeBar from '../common/NoticeBar';
import { AuthContext } from "../firebase/AuthContext";
import { firestore } from "../firebase/config";

// function createData(id, name, address) {
//   return { id, name, address };
// }

const headCells = [
  { id: 'id', numeric: true, disablePadding: true, label: 'Id', },
  { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
  { id: 'address', numeric: false, disablePadding: false, label: 'Address' },
  { id: 'email', numeric: false, disablePadding: false, label: 'Email' },
  { id: 'phone', numeric: false, disablePadding: false, label: 'Phone' },
];

// const rows = [
//   createData(1, 'Bill Chen', "2783 Don Mills Rd, North York, ON M2B 3B5",),
//   createData(2, 'Hudlow Gandalf', "2783 Wall Street, North York, ON M2B 3B5",),
//   createData(3, 'Elon Mask', "2783 GodStone St., North York, ON M2B 3B5",),
//   createData(4, 'Elon Mask', "2783 Don Mills Rd, North York, ON M2B 3B5",),
//   createData(5, 'Elon Mask', "2783 Don Mills Rd, North York, ON M2B 3B5",),
//   createData(6, 'Elon Mask', "2783 Don Mills Rd, North York, ON M2B 3B5",),
//   createData(7, 'Elon Mask', "2783 Don Mills Rd, North York, ON M2B 3B5",),
//   createData(8, 'Elon Mask', "2783 Don Mills Rd, North York, ON M2B 3B5",),
//   createData(9, 'Elon Mask', "2783 Don Mills Rd, North York, ON M2B 3B5",),
//   createData(10, 'Elon Mask', "2783 Don Mills Rd, North York, ON M2B 3B5",),
//   createData(11, 'Elon Mask', "2783 Don Mills Rd, North York, ON M2B 3B5",),
//   createData(12, 'Elon Mask', "2783 Don Mills Rd, North York, ON M2B 3B5",),
// ];

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: theme.breakpoints.values.md,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  hiddenCell: {
    display: "none",
  },
  tableRow: theme.palette.type === 'light'
    ? {
      "&.Mui-selected, &.Mui-selected:hover": {
        backgroundColor: lighten(theme.palette.primary.light, 0.85),
        "& > .MuiTableCell-root": {
          color: theme.palette.primary.main,
        }
      }
    }
    : {
      "&.Mui-selected, &.Mui-selected:hover": {
        backgroundColor: lighten(theme.palette.primary.dark, 0.85),
        "& > .MuiTableCell-root": {
          color: theme.palette.primary.main,
        }
      }
    },

}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}


function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead style={{ backgroundColor: '#e0e0e0', }}>
      <TableRow>
        <TableCell >

        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={'none'}
            sortDirection={orderBy === headCell.id ? order : false}
            className={headCell.id === "id" ? classes.hiddenCell : ""}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function SearchAddressTable({ searchWord, setSelectedAddress, update, isShipping }) {
  const classes = useStyles();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [selected, setSelected] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0);
  const regex = new RegExp(searchWord, 'i'); //ignore case.
  const { currentUser } = useContext(AuthContext);
  const [rows, setRows] = useState([]);
  const [notice, setNotice] = useState({ message: "", open: false, severity: "error" });

  useEffect(() => {
    async function fetchData() {
      setSelectedAddress(null);
      // await sleep(1000);
      setIsLoading(false);

      try {
        const userData = await firestore.collection("Customer").doc(currentUser.uid).get();
        const addresses = userData.data().addresses.filter(address => address.is_shipping === isShipping);
        setRows(addresses);

      } catch (error) {
        // toast.error(error.message);
        console.error(error);
        setNotice({ ...notice, open: true, message: error.toString() });
      }
    }

    fetchData();

  }, [update]);

  // useEffect(() => {
  //   console.log("i am here", update);
  // }, [update]);

  const combineAddress = (row) => `${row.address1}, ${row.address2} ${row.city}, ${row.province} ${row.country} ${row.postal_code}`;

  // use Regular Expression to search target.
  const filteredData = rows.filter(row => regex.test(row.name) || regex.test(combineAddress(row)) || regex.test(row.email) || regex.test(row.phone));

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClick = (event, rowId) => {
    let newSelected = [];
    newSelected.push(rowId);
    setSelected(newSelected);
    setSelectedAddress(rows.find(row => row.id === rowId));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <>
      <NoticeBar notice={notice} setNotice={setNotice} />
      <TableContainer component={Paper} variant="outlined" margin="20">
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size="medium"
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
          />

          {isLoading &&
            <TableBody>
              <TableRow>
                <TableCell colSpan={headCells.length} align="center">
                  <Typography variant="h6" color="secondary" gutterBottom>One moment, querying...</Typography>
                  <CircularProgress color="secondary" />
                  <Skeleton />
                  <Skeleton animation="wave" />
                  <Skeleton animation="wave" />
                </TableCell>
              </TableRow>
            </TableBody>
          }

          <TableBody>
            {stableSort(filteredData, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, row.id)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    className={classes.tableRow}
                  >
                    <TableCell component="th" id={labelId} scope="row" className={classes.hiddenCell} >{row.id}</TableCell>
                    <TableCell padding="checkbox">
                      <Radio checked={isItemSelected} color="primary" />
                    </TableCell>
                    <TableCell padding="none">{row.name}</TableCell>
                    <TableCell padding="none">{combineAddress(row)}</TableCell>
                    <TableCell padding="none">{row.email}</TableCell>
                    <TableCell padding="none">{row.phone}</TableCell>
                  </TableRow>
                );
              })}
            {!isLoading && emptyRows > 0 && (
              <TableRow style={{ height: 33 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
}
