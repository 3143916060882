import DateFnsUtils from '@date-io/date-fns';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, IconButton, InputAdornment, MenuItem, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import firebase from 'firebase/app';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clearFields, { setFields } from "../businessLogic/clearFields";
import { BcCheckbox, BcTextField } from '../common/BcComponents';
import NoticeBar from '../common/NoticeBar';
import { AuthContext } from "../firebase/AuthContext";
import { firestore } from "../firebase/config";
import { fetchCountry, fetchRegion } from '../store/countrySlice';
import { initialState } from "../store/shippingSlice";
import SearchAddressTable from './SearchAddresTable';

const useStyles = makeStyles((theme) => ({
  label: {
    margin: 0,
  },
  dialogTitle: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    paddingTop: "0.6rem",
    paddingBottom: "0.6rem",
  },
  adornment: {
    marginLeft: 0,
    color: "grey",
  },
  adornmentPadding: {
    paddingRight: 0,
  },
  searchButton: {
    width: '60%',
    margin: "1rem auto 1.5rem auto",
  },

}));

export default function Contact(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { person, setFieldValue, handleChange, setFieldTouched } = props;
  const [open, setOpen] = useState(false);
  const [openAddressDialog, setAddressDialogOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [searchWord, setSearchWord] = useState('');
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [update, setUpdate] = useState('');
  const isShipping = person.name === "shipperName";
  const { currentUser } = useContext(AuthContext);
  const [notice, setNotice] = useState({ message: "", open: false, severity: "error" });
  const countries = useSelector((state) => state.country.countries);
  const regions = useSelector((state) => isShipping ? state.country.shipperRegions : state.country.receiverRegions);
  const selectedCountry = props.values[person.country];

  useEffect(() => {
    async function fetchData() {
      if (countries.list.length <= 1) {
        dispatch(fetchCountry());
      }

      if (regions.list.length <= 1) {
        isShipping ? dispatch(fetchRegion('CA', 'shipper')) : dispatch(fetchRegion('CA', 'receiver'));
      }
    }

    fetchData();

    // setFieldValue("shipperProvince", "AB", false);
    // setFieldTouched("shipperProvince", true);
    // setFieldValue("shipperProvince", "ON", false);
    // setFieldTouched("shipperProvince", true);

  }, []);

  useEffect(() => {
    async function fetchData() {
      isShipping ? dispatch(fetchRegion(selectedCountry, 'shipper')) : dispatch(fetchRegion(selectedCountry, 'receiver'));
    }
    fetchData();
  }, [selectedCountry])

  const handleCountryChange = (e) => {
    handleChange(e);
    setFieldValue(person.province, "", false);
  }

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const clearForm = () => {
    clearFields(person, initialState, setFieldValue, setFieldTouched);
    setOpen(false);
  };

  const openDialog = () => {
    setOpen(true);
  }

  const searchAddress = () => {
    setSearchWord('');
    setAddressDialogOpen(true);
  }

  const handleSearch = (event) => {
    setSearchWord(event.target.value);
  };

  const handleDelete = async () => {
    if (!selectedAddress) {
      setNotice({ ...notice, open: true, message: "Please select an address." });
      return;
    };

    try {
      const userRef = firestore.collection("Customer").doc(currentUser.uid);
      await userRef.update({ addresses: firebase.firestore.FieldValue.arrayRemove(selectedAddress) });
      setUpdate("address table needs update" + Math.random());

    } catch (error) {
      setNotice({ ...notice, open: true, message: error.message });
    }

  };

  const handlePickAddress = () => {
    if (!selectedAddress) {
      setNotice({ ...notice, open: true, message: "Please select an address." });
      return;
    };

    const people = isShipping ? "shipper" : "receiver";
    const newValues = {};
    newValues[`${people}Name`] = selectedAddress.name;
    newValues[`${people}Address1`] = selectedAddress.address1;
    newValues[`${people}Address2`] = selectedAddress.address2;
    newValues[`${people}Country`] = selectedAddress.country;
    newValues[`${people}Province`] = selectedAddress.province;
    newValues[`${people}City`] = selectedAddress.city;
    newValues[`${people}PostalCode`] = selectedAddress.postal_code;
    newValues[`${people}Email`] = selectedAddress.email;
    newValues[`${people}Phone`] = selectedAddress.phone;

    setFields(person, newValues, setFieldValue, setFieldTouched);
    setAddressDialogOpen(false);
  };

  // console.log(world);
  // if (world && world.error) {
  //   setNotice({ ...notice, open: true, message: error.toString() });
  // }

  return (
    <React.Fragment>
      <NoticeBar notice={notice} setNotice={setNotice} />

      <Dialog open={open} onClose={handleClose} >
        <DialogContent>
          <DialogContentText id="alert-dialog-description" variant="h6">
            Are you sure to clear this form?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={clearForm} variant="text">
            Confirm
          </Button>
          <Button onClick={handleClose} variant="text" autoFocus disableFocusRipple>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openAddressDialog} onClose={handleClose} maxWidth="lg" >
        <DialogTitle className={classes.dialogTitle}>Pick Address</DialogTitle>
        <DialogContent>
          <div className={classes.searchButton}>
            <TextField label="Search" name="search" onChange={handleSearch}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" className={classes.adornment}>
                    <SearchIcon />
                  </InputAdornment>
                ),
                className: (classes.adornmentPadding),
              }}

            />
          </div>
          <SearchAddressTable searchWord={searchWord} setSelectedAddress={setSelectedAddress} update={update} isShipping={isShipping} />
        </DialogContent>
        <DialogActions style={{ margin: "1rem" }}>
          <div style={{ flexGrow: 1, }}>
            <Button onClick={handleDelete} variant="outlined">
              Delete Selected Address
            </Button>
          </div>
          <Button onClick={handlePickAddress} >
            Select
          </Button>
          <Button onClick={() => setAddressDialogOpen(false)} disableFocusRipple variant="outlined">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <div style={{ display: 'flex', alignItems: "baseline", }}>
        <Typography variant="h5" gutterBottom style={{ flexGrow: 1 }}>{person.title}</Typography>
        <Button variant="contained" color="primary" size="small" disableElevation onClick={openDialog} disableFocusRipple>Clear</Button>
      </div>
      <Divider style={{ marginBottom: 10, marginTop: 5 }} />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div style={{ display: "flex" }}>
            <BcTextField label={person.nameLabel} name={person.name} required autoComplete="name"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" className={classes.adornment}>
                    <IconButton onClick={() => searchAddress()}>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
                className: (classes.adornmentPadding),
              }}
            />
            <BcCheckbox name={person.addressSaved} label="Save Address" style={{ textAlign: "right", margin: 0, whiteSpace: "pre" }} />
          </div>
        </Grid>

        <Grid item xs={12} >
          <BcTextField label="Address 1" name={person.address1} required autoComplete="address-line1" />
        </Grid>

        <Grid item xs={12} >
          <BcTextField label="Address 2 (Suite number)" name={person.address2} autoComplete="address-line2" placeholder="Address 2 Unit number" />
        </Grid>

        <Grid item xs={12} md={6}>
          <BcTextField select name={person.country} label="Country" required onChange={handleCountryChange}>
            {countries.list.map((country, index) => (<MenuItem key={index} value={country.short_code}>{country.full_name}</MenuItem>))}
          </BcTextField>
          {/* 
          <FormControl variant="outlined" size="small" fullWidth required >
            <InputLabel id={person.country}>Country</InputLabel>
            <Select native ref={countryRef} labelId={person.country} onChange={handleChange} name={person.country} label="Country" value={props.values[person.country]}>
              {
                isShipping ? <>
                  <option value="CA">Canada</option>
                  <option value="US">United States</option>
                </> : countries.list.map(country => (<option key={country.short_code} value={country.short_code}>{country.full_name}</option>))
                //countries.list.map(country => (<MenuItem key={country.short_code} value={country.short_code}>{country.full_name}</MenuItem>))
              }
            </Select>
          </FormControl> */}
        </Grid>

        <Grid item xs={12} md={6}>
          <BcTextField select name={person.province} label="Province" required >
            {regions.list.map((region, index) => (<MenuItem key={index} value={region.shortCode}>{region.name}</MenuItem>))}
          </BcTextField>

          {/* <FormControl variant="outlined" size="small" fullWidth required >
            <InputLabel id="province">Province</InputLabel>
            <Select native ref={provinceRef} labelId="province" onChange={handleChange} name={person.province} label="Province" value={props.values[person.province]}>

              {
                regions.list.map(region => (<option key={region.shortCode} value={region.shortCode}>{region.name}</option>))
              }
            </Select>
          </FormControl> */}
        </Grid>

        <Grid item xs={12} md={6}>
          <BcTextField label="City" name={person.city} required autoComplete="address-level2" />
        </Grid>

        <Grid item xs={12} md={6}>
          <BcTextField label="Zip/Postal" name={person.postalCode} required />
        </Grid>

        <Grid item xs={12} md={6}>
          <BcTextField label="Phone" name={person.phone} required autoComplete="tel" />
        </Grid>

        <Grid item xs={12} md={6}>
          <BcTextField label="Email" name={person.email} required autoComplete="email" />
        </Grid>

        {isShipping &&
          <Grid item xs={12} md={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="yyyy-MM-dd"
                margin="none"
                name={person.shipDate}
                label="Ship Date"
                value={selectedDate}
                onChange={handleDateChange}
                fullWidth
                autoOk
              />
            </MuiPickersUtilsProvider>
          </Grid>
        }

        {!isShipping &&
          <Grid item xs={12} style={{ height: "58px", paddingTop: "7px", }} >
            <BcCheckbox name={person.confirm} label="Signature Required" />
          </Grid>
        }

        {/* {isShipping ?
          <Grid item xs={12} md={6} style={{ alignSelf: "flex-end", }} >
            <BcCheckbox name={person.confirm} label="Confirm Delivery" />
          </Grid>
          :
          <Grid item xs={12} style={{ height: "58px", paddingTop: "7px", }} >
            <BcCheckbox name={person.confirm} label="Signature Required" />
          </Grid>
        } */}

        <Grid item xs={12} >
          <BcTextField label="Instructions" name={person.instructions} rows={3} multiline />
        </Grid>
      </Grid>
    </React.Fragment >
  );
}
