export default function clearFields(fields, initialValues, setFieldValue, setFieldTouched) {
  for (const key in fields) {
    let value = fields[key];
    setFieldValue(value, initialValues[value], false);
    setFieldTouched(value, false);
  }
}

export function setFields(fields, newValues, setFieldValue, setFieldTouched) {
  const skipFields = ["title", "nameLabel", "addressSaved", "confirm", "shipDate"];
  for (const key in fields) {
    if (skipFields.includes(key)) {
      continue;
    }

    let value = fields[key];
    setFieldValue(value, newValues[value], false);
    setFieldTouched(value, false);
  }
}