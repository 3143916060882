import React, { useState, useContext } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Typography, Button, TextField, Paper, useMediaQuery, CircularProgress, Snackbar } from '@material-ui/core'
import SendIcon from '@material-ui/icons/Send';

import PhoneIcon from '@material-ui/icons/Phone';
import MuiAlert from '@material-ui/lab/Alert';
import EmailIcon from '@material-ui/icons/Email';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { Form, Formik, useField } from 'formik'
import * as yup from 'yup'
import { AuthContext } from "../firebase/AuthContext"
import { firestore } from '../firebase/config'
import ShipEnvoy from './ShipEnvoy';
import Image from '../img/support.jpg'

import firebase from 'firebase/functions'




const initialValues = {
  name: "",
  trackingNumber: "",
  company: "",
  email: "",
  subject: "",
  message: ""
}
const validationSchema = yup.object({
  name: yup.string().required("Full name is required."),
  trackingNumber: yup.string().required("Tracking number is required."),
  email: yup.string().email("Invalid email address.").required("Email is required."),
  company: yup.string(),
  subject: yup.string().required("Subject is required"),
  message: yup.string().required("Message is required"),
})
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    position: "relative",
    margin: "0",
    padding: "0",
    boxSizing: "borderBox",
  },
  paper: {
    width: "80%",
    margin: theme.spacing(2),
    padding: theme.spacing(1.4),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // backgroundColor: "rgb(128,128,128, 0.2)"
    [theme.breakpoints.down("xs")]: {
      width: "90%"
    }
  },
  top: {
    height: "20vw",
    width: "100%",
    backgroundImage: `linear-gradient(to right, rgba(63, 91, 212,0.7), rgba(232, 121, 121,0.7)),url(${Image})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 22vw",
    textAlign: "center",
    color: "#ffffff"
  },
  content: {
    [theme.breakpoints.down("md")]: {
      display: "none"
    }
  },
  a: {
    textDecoration: "none",
    color: "inherit",
    fontSize: "inherit",
  },
  icon: {

  }

}));

export default function Support() {
  const classes = useStyles();
  const theme = useTheme();
  const matchesSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"));
  const { currentUser } = useContext(AuthContext);
  const [isLoading, setLoading] = useState(false)
  const [openSnaSucc, setopenSnaSucc] = useState(false);
  const [openSnacFail, setOpenSnaFail] = useState(false);

  //sending email///////
  const handleSubmit = (values, props) => {
    props.resetForm()
    setLoading(true)
    let { name, trackingNumber, company, message, email, subject } = values
    message = message.split('\n').join('<br>')
    let html = `Full name: ${name}<br>Tracking number: ${trackingNumber}<br>Email: ${email}<br>company: ${company}<br> Tracking#: ${trackingNumber}<br><br><br>${message}`
    firestore.collection('mail').add({
      to: "qkrwjddnr218@gmail.com",
      message: {
        subject: `${name}: ${subject}`,
        html: html,
        user_id: currentUser.uid,
        email: email,
        company: company,
        tracking_number: trackingNumber,
        message: message,
      },
    })
      .then(res => {
        console.log(res)
        setLoading(false)
        setopenSnaSucc(true)
      })
      .catch(error => {
        console.log(error)
        setLoading(false)
        setOpenSnaFail(true)
      })

  }
  const snackSuccess = () => {
    setopenSnaSucc(false)
    setLoading(false)

  }
  const snackFail = () => {
    setOpenSnaFail(false)
    setLoading(false)

  }
  const SuTextField = (props) => {
    const [field, meta] = useField(props);
    const error = meta.error && meta.touched ? meta.error : "";
    return (<TextField {...props} {...field} helperText={error} error={!!error} variant="outlined" fullWidth size="small" disabled={isLoading ? true : false} />);
  };
  return (
    <ShipEnvoy>
      <Grid container className={classes.root} direction="column" justify="center" alignItems="center" spacing={6}>


        <Grid className={classes.top} item container direction="column" justify="center" alignItems="center" >
          <Grid item style={{ marginBottom: "30px" }}>
            <Typography variant={matchesSM ? "h4" : "h3"}>Support Centre</Typography>
          </Grid>
          <Grid item className={classes.content}>
            <Typography>We constantly strive for improvement. As we place our customers above everything else,</Typography>
          </Grid>
          <Grid item className={classes.content}>
            <Typography>our team will always be happy to assist you, whether it's a question or feedback.</Typography>
          </Grid>
        </Grid>

        <Grid className={classes.middle} item container direction="row" justify="space-around" alignItems="center" xs={12} spacing={3}>
          <Grid item container direction="column" justify="center" alignItems="center" lg={3} md={12} xs={12}>
            <Grid item >
              <LocationOnIcon className={classes.icon} />
            </Grid>
            <Grid item style={{ textAlign: "center" }} >
              <Typography>Toronto</Typography>
            </Grid>
          </Grid>
          <Grid item container direction="column" justify="center" alignItems="center" lg={3} md={12} xs={12}>
            <Grid item >
              <EmailIcon className={classes.icon} />
            </Grid>
            <Grid item >
              <Typography><a className={classes.a} href="mailTo:"> customerservice@shipenvoy.com</a></Typography>
            </Grid>
          </Grid>
          <Grid item container direction="column" justify="center" alignItems="center" lg={3} md={12} xs={12}>
            <Grid item >
              <PhoneIcon className={classes.icon} />
            </Grid>
            <Grid item >
              <Typography><a className={classes.a} href="tel:">(905)-456-7890</a></Typography>
            </Grid>
          </Grid>
        </Grid>


        <Paper className={classes.paper} variant="outlined" elevation={0}>
          <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
            {formProps => {
              return (
                <Form style={{ width: "100%" }}>
                  <Grid className={classes.bottom} item container direction="column" justify="center" alignItems="center" xs={12} spacing={3}>
                    <Grid item container direction="row" justify="center" alignItems="center" xs={12} spacing={3} style={{ marginTop: "10px" }}>
                      <Grid item xs={12} md={6}>
                        <SuTextField name="name" label="Full Name" />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <SuTextField name="trackingNumber" label="Tracking Number" />
                      </Grid>
                    </Grid>
                    <Grid item container direction="row" justify="center" alignItems="center" xs={12} spacing={3}>
                      <Grid item xs={12} md={6}>
                        <SuTextField name="company" label="Company" />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <SuTextField name="email" label="Email" />
                      </Grid>
                    </Grid>
                    <Grid item container xs={12} spacing={3}>
                      <Grid item xs={12}>
                        <SuTextField name="subject" label="Subject" />
                      </Grid>
                      <Grid item xs={12}>
                        <SuTextField name="message" placeholder="Leave your message..." multiline rows={7} />
                      </Grid>
                    </Grid>
                    <Grid item container justify="flex-end" alignItems="center" xs={12} spacing={3} style={{ marginBottom: "10px" }}>
                      <Grid item >
                        {isLoading ? <CircularProgress /> : <Button type="submit" variant="contained" color="primary" endIcon={<SendIcon />} >
                          Send
                      </Button>}
                      </Grid>
                    </Grid>
                    <Snackbar open={openSnaSucc} autoHideDuration={6000} onClose={snackSuccess} >
                      <MuiAlert onClose={snackSuccess} elevation={6} variant="filled" severity="success" >
                        Message was successfully sent!
                       </MuiAlert>
                    </Snackbar>
                    <Snackbar open={openSnacFail} autoHideDuration={6000} onClose={snackFail}>
                      <MuiAlert onClose={snackFail} elevation={6} variant="filled" severity="error">
                        Oops something went wrong!
                      </MuiAlert>
                    </Snackbar>
                  </Grid>
                </Form>
              )
            }}
          </Formik>
        </Paper>




      </Grid>
    </ShipEnvoy>
  )

}


