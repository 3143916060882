import { Carriers, UseTestData } from "./utility";

const __rawRates = {
  "data": {
    "data": {
      "rate_response": {
        "rates": [
          {
            "rate_id": "se-523024928",
            "rate_type": "shipment",
            "carrier_id": "se-538063",
            "shipping_amount": {
              "currency": "usd",
              "amount": 52.02
            },
            "insurance_amount": {
              "currency": "usd",
              "amount": 0
            },
            "confirmation_amount": {
              "currency": "usd",
              "amount": 0
            },
            "other_amount": {
              "currency": "usd",
              "amount": 13.67
            },
            "zone": null,
            "package_type": null,
            "delivery_days": 1,
            "guaranteed_service": false,
            "estimated_delivery_date": "2021-03-11T10:00:00Z",
            "carrier_delivery_days": "Tomorrow by 10:00 AM",
            "ship_date": "2021-03-10T00:00:00Z",
            "negotiated_rate": false,
            "service_type": "FedEx First Overnight®",
            "service_code": "fedex_first_overnight",
            "trackable": true,
            "carrier_code": "fedex",
            "carrier_nickname": "Fedex",
            "carrier_friendly_name": "FedEx",
            "validation_status": "valid",
            "warning_messages": [

            ],
            "error_messages": [

            ]
          },
          {
            "rate_id": "se-523024929",
            "rate_type": "shipment",
            "carrier_id": "se-538063",
            "shipping_amount": {
              "currency": "usd",
              "amount": 24.43
            },
            "insurance_amount": {
              "currency": "usd",
              "amount": 0
            },
            "confirmation_amount": {
              "currency": "usd",
              "amount": 0
            },
            "other_amount": {
              "currency": "usd",
              "amount": 6.42
            },
            "zone": null,
            "package_type": null,
            "delivery_days": 1,
            "guaranteed_service": false,
            "estimated_delivery_date": "2021-03-11T12:00:00Z",
            "carrier_delivery_days": "Tomorrow by 12:00 PM",
            "ship_date": "2021-03-10T00:00:00Z",
            "negotiated_rate": false,
            "service_type": "FedEx Priority Overnight®",
            "service_code": "fedex_priority_overnight",
            "trackable": true,
            "carrier_code": "fedex",
            "carrier_nickname": "Fedex",
            "carrier_friendly_name": "FedEx",
            "validation_status": "valid",
            "warning_messages": [

            ],
            "error_messages": [

            ]
          },
          {
            "rate_id": "se-523024930",
            "rate_type": "shipment",
            "carrier_id": "se-538063",
            "shipping_amount": {
              "currency": "usd",
              "amount": 15.55
            },
            "insurance_amount": {
              "currency": "usd",
              "amount": 0
            },
            "confirmation_amount": {
              "currency": "usd",
              "amount": 0
            },
            "other_amount": {
              "currency": "usd",
              "amount": 4.09
            },
            "zone": null,
            "package_type": null,
            "delivery_days": 1,
            "guaranteed_service": false,
            "estimated_delivery_date": "2021-03-11T17:00:00Z",
            "carrier_delivery_days": "Tomorrow by 05:00 PM",
            "ship_date": "2021-03-10T00:00:00Z",
            "negotiated_rate": false,
            "service_type": "FedEx Standard Overnight®",
            "service_code": "fedex_standard_overnight",
            "trackable": true,
            "carrier_code": "fedex",
            "carrier_nickname": "Fedex",
            "carrier_friendly_name": "FedEx",
            "validation_status": "valid",
            "warning_messages": [

            ],
            "error_messages": [

            ]
          },
          {
            "rate_id": "se-523024931",
            "rate_type": "shipment",
            "carrier_id": "se-538063",
            "shipping_amount": {
              "currency": "usd",
              "amount": 11.2
            },
            "insurance_amount": {
              "currency": "usd",
              "amount": 0
            },
            "confirmation_amount": {
              "currency": "usd",
              "amount": 0
            },
            "other_amount": {
              "currency": "usd",
              "amount": 2.95
            },
            "zone": null,
            "package_type": null,
            "delivery_days": 1,
            "guaranteed_service": false,
            "estimated_delivery_date": "2021-03-11T23:59:00Z",
            "carrier_delivery_days": "1",
            "ship_date": "2021-03-10T00:00:00Z",
            "negotiated_rate": false,
            "service_type": "FedEx Ground®",
            "service_code": "fedex_ground",
            "trackable": true,
            "carrier_code": "fedex",
            "carrier_nickname": "Fedex",
            "carrier_friendly_name": "FedEx",
            "validation_status": "has_warnings",
            "warning_messages": [
              "FedEx may add a Home Delivery Surcharge to this shipment later if this is a residential address."
            ],
            "error_messages": [

            ]
          },
          {
            "rate_id": "se-523024932",
            "rate_type": "shipment",
            "carrier_id": "se-538063",
            "shipping_amount": {
              "currency": "usd",
              "amount": 18.66
            },
            "insurance_amount": {
              "currency": "usd",
              "amount": 0
            },
            "confirmation_amount": {
              "currency": "usd",
              "amount": 0
            },
            "other_amount": {
              "currency": "usd",
              "amount": 4.9
            },
            "zone": null,
            "package_type": null,
            "delivery_days": null,
            "guaranteed_service": false,
            "estimated_delivery_date": null,
            "carrier_delivery_days": "Not Available",
            "ship_date": "2021-03-10T00:00:00Z",
            "negotiated_rate": false,
            "service_type": "FedEx 2Day®",
            "service_code": "fedex_2day",
            "trackable": true,
            "carrier_code": "fedex",
            "carrier_nickname": "Fedex",
            "carrier_friendly_name": "FedEx",
            "validation_status": "valid",
            "warning_messages": [

            ],
            "error_messages": [

            ]
          },
          {
            "rate_id": "se-523024933",
            "rate_type": "shipment",
            "carrier_id": "se-538063",
            "shipping_amount": {
              "currency": "usd",
              "amount": 25
            },
            "insurance_amount": {
              "currency": "usd",
              "amount": 0
            },
            "confirmation_amount": {
              "currency": "usd",
              "amount": 0
            },
            "other_amount": {
              "currency": "usd",
              "amount": 6.57
            },
            "zone": null,
            "package_type": null,
            "delivery_days": null,
            "guaranteed_service": false,
            "estimated_delivery_date": null,
            "carrier_delivery_days": "Not Available",
            "ship_date": "2021-03-10T00:00:00Z",
            "negotiated_rate": false,
            "service_type": "FedEx Economy",
            "service_code": "fedex_express_saver",
            "trackable": true,
            "carrier_code": "fedex",
            "carrier_nickname": "Fedex",
            "carrier_friendly_name": "FedEx",
            "validation_status": "valid",
            "warning_messages": [

            ],
            "error_messages": [

            ]
          },
          {
            "rate_id": "se-523024934",
            "rate_type": "shipment",
            "carrier_id": "se-537955",
            "shipping_amount": {
              "currency": "usd",
              "amount": 35.46
            },
            "insurance_amount": {
              "currency": "usd",
              "amount": 0
            },
            "confirmation_amount": {
              "currency": "usd",
              "amount": 0
            },
            "other_amount": {
              "currency": "usd",
              "amount": 0
            },
            "zone": null,
            "package_type": null,
            "delivery_days": 1,
            "guaranteed_service": false,
            "estimated_delivery_date": "2021-03-11T00:00:00Z",
            "carrier_delivery_days": "1",
            "ship_date": "2021-03-10T00:00:00Z",
            "negotiated_rate": false,
            "service_type": "Purolator Express 9 AM",
            "service_code": "purolator_express_9am ",
            "trackable": true,
            "carrier_code": "purolator_ca",
            "carrier_nickname": "Purolator ",
            "carrier_friendly_name": "Purolator Canada",
            "validation_status": "valid",
            "warning_messages": [

            ],
            "error_messages": [

            ]
          },
          {
            "rate_id": "se-523024935",
            "rate_type": "shipment",
            "carrier_id": "se-537955",
            "shipping_amount": {
              "currency": "usd",
              "amount": 23.72
            },
            "insurance_amount": {
              "currency": "usd",
              "amount": 0
            },
            "confirmation_amount": {
              "currency": "usd",
              "amount": 0
            },
            "other_amount": {
              "currency": "usd",
              "amount": 0
            },
            "zone": null,
            "package_type": null,
            "delivery_days": 1,
            "guaranteed_service": false,
            "estimated_delivery_date": "2021-03-11T00:00:00Z",
            "carrier_delivery_days": "1",
            "ship_date": "2021-03-10T00:00:00Z",
            "negotiated_rate": false,
            "service_type": "Purolator Express 10:30 AM",
            "service_code": "purolator_express_1030am ",
            "trackable": true,
            "carrier_code": "purolator_ca",
            "carrier_nickname": "Purolator ",
            "carrier_friendly_name": "Purolator Canada",
            "validation_status": "valid",
            "warning_messages": [

            ],
            "error_messages": [

            ]
          },
          {
            "rate_id": "se-523024936",
            "rate_type": "shipment",
            "carrier_id": "se-537955",
            "shipping_amount": {
              "currency": "usd",
              "amount": 52.03
            },
            "insurance_amount": {
              "currency": "usd",
              "amount": 0
            },
            "confirmation_amount": {
              "currency": "usd",
              "amount": 0
            },
            "other_amount": {
              "currency": "usd",
              "amount": 0
            },
            "zone": null,
            "package_type": null,
            "delivery_days": 1,
            "guaranteed_service": false,
            "estimated_delivery_date": "2021-03-11T00:00:00Z",
            "carrier_delivery_days": "1",
            "ship_date": "2021-03-10T00:00:00Z",
            "negotiated_rate": false,
            "service_type": "Purolator Express Evening",
            "service_code": "purolator_express_evening ",
            "trackable": true,
            "carrier_code": "purolator_ca",
            "carrier_nickname": "Purolator ",
            "carrier_friendly_name": "Purolator Canada",
            "validation_status": "valid",
            "warning_messages": [

            ],
            "error_messages": [

            ]
          },
          {
            "rate_id": "se-523024937",
            "rate_type": "shipment",
            "carrier_id": "se-537955",
            "shipping_amount": {
              "currency": "usd",
              "amount": 13.11
            },
            "insurance_amount": {
              "currency": "usd",
              "amount": 0
            },
            "confirmation_amount": {
              "currency": "usd",
              "amount": 0
            },
            "other_amount": {
              "currency": "usd",
              "amount": 0
            },
            "zone": null,
            "package_type": null,
            "delivery_days": 1,
            "guaranteed_service": false,
            "estimated_delivery_date": "2021-03-11T00:00:00Z",
            "carrier_delivery_days": "1",
            "ship_date": "2021-03-10T00:00:00Z",
            "negotiated_rate": false,
            "service_type": "Purolator Express",
            "service_code": "purolator_express",
            "trackable": true,
            "carrier_code": "purolator_ca",
            "carrier_nickname": "Purolator ",
            "carrier_friendly_name": "Purolator Canada",
            "validation_status": "valid",
            "warning_messages": [

            ],
            "error_messages": [

            ]
          },
          {
            "rate_id": "se-523024938",
            "rate_type": "shipment",
            "carrier_id": "se-537955",
            "shipping_amount": {
              "currency": "usd",
              "amount": 12.93
            },
            "insurance_amount": {
              "currency": "usd",
              "amount": 0
            },
            "confirmation_amount": {
              "currency": "usd",
              "amount": 0
            },
            "other_amount": {
              "currency": "usd",
              "amount": 0
            },
            "zone": null,
            "package_type": null,
            "delivery_days": 1,
            "guaranteed_service": false,
            "estimated_delivery_date": "2021-03-11T00:00:00Z",
            "carrier_delivery_days": "1",
            "ship_date": "2021-03-10T00:00:00Z",
            "negotiated_rate": false,
            "service_type": "Purolator Ground",
            "service_code": "purolator_ground ",
            "trackable": true,
            "carrier_code": "purolator_ca",
            "carrier_nickname": "Purolator ",
            "carrier_friendly_name": "Purolator Canada",
            "validation_status": "valid",
            "warning_messages": [

            ],
            "error_messages": [

            ]
          }
        ],
        "invalid_rates": [

        ],
        "rate_request_id": "se-49784019",
        "shipment_id": "se-88380398",
        "created_at": "2021-03-10T16:43:00.9077566Z",
        "status": "partial",
        "errors": [
          {
            "error_source": "shipengine",
            "error_type": "business_rules",
            "error_code": "unspecified",
            "message": "A shipping carrier error occurred: The declared value is missing.,Failure - request",
            "carrier_id": "se-537961",
            "carrier_code": "dhl_express_canada",
            "carrier_name": "DHL Express Canada"
          }
        ]
      },
      "shipment_id": "se-88380398",
      "carrier_id": null,
      "service_code": null,
      "external_shipment_id": null,
      "ship_date": "2021-03-10T00:00:00Z",
      "created_at": "2021-03-10T16:42:55.213Z",
      "modified_at": "2021-03-10T16:42:55.213Z",
      "shipment_status": "pending",
      "ship_to": {
        "name": "Nancy Hudlow",
        "phone": "4164328999",
        "company_name": null,
        "address_line1": "28 Bowerbank Dr",
        "address_line2": "Unit 203",
        "address_line3": null,
        "city_locality": "North York",
        "state_province": "ON",
        "postal_code": "M2M 1Z8",
        "country_code": "CA",
        "address_residential_indicator": "unknown"
      },
      "ship_from": {
        "name": "Ellie Du",
        "phone": "9056722339",
        "company_name": null,
        "address_line1": "374 Sheppard Ave E",
        "address_line2": "",
        "address_line3": null,
        "city_locality": "North York",
        "state_province": "ON",
        "postal_code": "M2N 3B6",
        "country_code": "CA",
        "address_residential_indicator": "unknown"
      },
      "warehouse_id": null,
      "return_to": {
        "name": "Ellie Du",
        "phone": "9056722339",
        "company_name": null,
        "address_line1": "374 Sheppard Ave E",
        "address_line2": "",
        "address_line3": null,
        "city_locality": "North York",
        "state_province": "ON",
        "postal_code": "M2N 3B6",
        "country_code": "CA",
        "address_residential_indicator": "unknown"
      },
      "confirmation": "none",
      "customs": null,
      "external_order_id": null,
      "order_source_code": null,
      "advanced_options": {
        "bill_to_account": null,
        "bill_to_country_code": null,
        "bill_to_party": null,
        "bill_to_postal_code": null,
        "contains_alcohol": false,
        "delivered_duty_paid": false,
        "non_machinable": false,
        "saturday_delivery": false,
        "dry_ice": false,
        "dry_ice_weight": null,
        "freight_class": null,
        "custom_field1": null,
        "custom_field2": null,
        "custom_field3": null,
        "collect_on_delivery": null
      },
      "insurance_provider": "none",
      "tags": [

      ],
      "packages": [
        {
          "package_code": "package",
          "weight": {
            "value": 1,
            "unit": "pound"
          },
          "dimensions": {
            "unit": "inch",
            "length": 1,
            "width": 1,
            "height": 1
          },
          "insured_value": {
            "currency": "usd",
            "amount": 0
          },
          "label_messages": {
            "reference1": null,
            "reference2": null,
            "reference3": null
          },
          "external_package_id": null
        }
      ],
      "total_weight": {
        "value": 1,
        "unit": "pound"
      },
      "items": [

      ]
    },
    "status": 200
  }
}

function processService(cadExchangeRate, customer, rate) {
  let amount = rate.shipping_amount.amount * cadExchangeRate;
  let surcharge = (rate.other_amount.amount + rate.insurance_amount.amount + rate.confirmation_amount.amount) * cadExchangeRate;
  let customerAmount = amount;
  let customerSurcharge = surcharge;
  let branchAmount = amount;
  let branchSurcharge = surcharge;

  //increase percentage based on branch or regular
  const branchRate = customer.branch_rate.rates.find(r => r.carrier_id === rate.carrier_id && r.service_code === rate.service_code);
  const regularCustomerRate = customer.regular_rate.rates.find(r => r.carrier_id === rate.carrier_id && r.service_code === rate.service_code);

  // console.log(branchRate, regularCustomerRate);

  if (regularCustomerRate) {
    customerAmount = amount * (1 + regularCustomerRate.increase_percent / 100);
    customerSurcharge = customerSurcharge * (1 + regularCustomerRate.increase_percent / 100);
  }

  if (branchRate) {
    branchAmount = amount * (1 + branchRate.increase_percent / 100);
    branchSurcharge = branchSurcharge * (1 + branchRate.increase_percent / 100);
  }

  const service = {
    serviceType: rate.carrier_id === Carriers.DHL.id ? `DHL ${rate.service_type}` : rate.service_type,
    rateId: rate.rate_id,
    shippingAmount: customerAmount,
    surcharge: customerSurcharge,
    totalToCustomer: customerAmount + customerSurcharge,
    totalToBranch: branchAmount + branchSurcharge,
    deliveryDays: rate.delivery_days,
    rateInfo: rate, // for future use.
  };

  return service;
}

export default function processRate(cadExchangeRate, customer, rawRates) {
  rawRates = UseTestData ? __rawRates : rawRates;
  const ratesList = rawRates.data.data.rate_response.rates;
  const processedRates = [];

  for (const rate of ratesList) {
    processedRates.push(processService(cadExchangeRate, customer, rate));
  }
  return processedRates;
}

// const exampleRates = [
//   {
//     carrier: "DHL",
//     carrierId: "se-537961",
//     services: [
//       {
//         serviceType: "DHL overnight service",
//         rateId: "se-478759999",
//         shippingAmount: 38.75,
//         surcharge: 13.73,
//         deliveryDays: 1,
//       },
//       {
//         serviceType: "DHL superfast service",
//         rateId: "se-478758888",
//         shippingAmount: 56.13,
//         surcharge: 16.34,
//         deliveryDays: 3,
//       },
//     ]
//   },
// ];

// export default function processRate(rawRates) {
//   rawRates = __rawRates;
//   const ratesList = rawRates.data.data.rate_response.rates;
//   const processedRates = [];

//   for (const rate of ratesList) {
//     const entry = processedRates.find(r => r.carrierId === rate.carrier_id);
//     if (entry) {
//       // add a service to existing carrier
//       entry.services.push(processService(rate));
//     } else {
//       // add a new service to a new carrier
//       const rateEntry = {
//         carrier: rate.carrier_friendly_name,
//         carrierId: rate.carrier_id,
//         services: [processService(rate)],
//       }
//       processedRates.push(rateEntry);
//     }
//   }

//   processedRates.push(exampleRates[0]);

//   return processedRates;

// }