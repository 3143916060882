import { createSlice } from '@reduxjs/toolkit';
import { firestore } from '../firebase/config'

const initialState = {
  countries: { list: [{ full_name: "Canada", short_code: "CA" }] },
  regions: { list: [{ name: "Ontario", shortCode: "ON" }] },
  shipperRegions: { list: [{ name: "Ontario", shortCode: "ON" }] },
  receiverRegions: { list: [{ name: "Ontario", shortCode: "ON" }] },
  error: null,
};

const countrySlice = createSlice({
  name: "country",
  initialState,
  reducers: {
    saveCountry: (state, action) => {
      state.countries = action.payload;
      state.error = null;
    },
    saveRegion: (state, action) => {
      state.regions = action.payload;
      state.error = null;
    },
    saveShipperRegion: (state, action) => {
      state.shipperRegions = action.payload;
      state.error = null;
    },
    saveReceiverRegion: (state, action) => {
      state.receiverRegions = action.payload;
      state.error = null;
    },
    saveError: (state, action) => {
      state.error = action.payload;
    },
  },

});

export const {
  saveRegion,
  saveReceiverRegion,
  saveShipperRegion,
  saveCountry,
  saveError,
} = countrySlice.actions;

// export const selectUser = useSelector((state) => state.user);
// export const selectUser = state => state.user.email;

export const fetchCountry = () => async dispatch => {
  try {
    const countryData = (await firestore.collection("Country").doc("Countries").get()).data();
    dispatch(saveCountry(countryData));
  } catch (error) {
    console.error(error);
    dispatch(saveError(error.toString()));
  }
}

export const fetchRegion = (countryCode, target = "regions") => async dispatch => {
  try {
    const regionData = (await firestore.collection("Region").doc(countryCode).get()).data();
    switch (target) {
      case "shipper":
        dispatch(saveShipperRegion(regionData, target));
        break;
      case "receiver":
        dispatch(saveReceiverRegion(regionData, target));
        break;
      default:
        dispatch(saveRegion(regionData, target));
        break;
    }

  } catch (error) {
    console.error(error);
    dispatch(saveError(error.toString()));
  }
}

export default countrySlice.reducer;