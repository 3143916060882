import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../firebase/AuthContext';
import { clearShippingInfo } from '../store/shippingSlice';

export default function Logout() {

  const { logout } = useContext(AuthContext);
  const history = useHistory();
  const dispatch = useDispatch();

  async function userLogout() {
    try {
      await logout();
      dispatch(clearShippingInfo());
      history.push("/login");
    } catch (error) {
      console.error(`Failed to logout the account. Reason: ${error.message}`);
    }
  }
 
  userLogout();
  return null;
}