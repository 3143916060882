import { Button, Container, Divider, Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import firebase from 'firebase/app';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { toast } from 'react-toastify';
import processLabel from '../businessLogic/processLabel';
import { formatCurrency, UseTestData } from '../businessLogic/utility';
import Loading from "../common/Loading";
import NoticeBar from '../common/NoticeBar';
import { AuthContext } from "../firebase/AuthContext";
import { firestore } from '../firebase/config';
import { clearShippingInfo, selectReceiverAddress1, selectReceiverAddress2, selectReceiverAddress3, selectShipperAddress1, selectShipperAddress2, selectShipperAddress3 } from '../store/shippingSlice';

const useStyles = makeStyles((theme) => ({
  bottomMargin: {
    marginTop: theme.spacing(2),
  },

  label: {
    border: "1px solid #222",
  },

  amountTable: {
    fontSize: "1rem",
    width: "100%",
    paddingRight: theme.spacing(3),
  },

  amountAlign: {
    textAlign: "right",
  }

}));

export default function PrintLabel({ activeStep, setActiveStep }) {
  const { id: trackId } = useParams()
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const shippingState = useSelector((state) => state.shipping)
  const [isLoading, setIsLoading] = useState(true)
  const { currentUser } = useContext(AuthContext)
  const [notice, setNotice] = useState({ message: "", open: false, severity: "error" })
  const [label, setLabel] = useState({ shipmentCost: 0, surcharge: 0, totalAmount: 0 })

  useEffect(() => {
    // await sleep(5000);
    async function fetchData() {
      try {

        if (trackId) {
          const shippingRef = firestore.collection("Shipping").doc(trackId)
          const shippingData = (await shippingRef.get()).data()
          const rawLabel = { data: { data: shippingData } }
          setLabel(processLabel(rawLabel))
          return
        }

        // const rate_info = shippingState.rateInfo.rateInfo
        const envoy_rate_info = { ...shippingState.rateInfo }
        delete envoy_rate_info["rateInfo"]

        const shippingInfo = {
          "shipper_name": shippingState.shipperName,
          "shipper_address1": shippingState.shipperAddress1,
          "shipper_address2": shippingState.shipperAddress2,
          "shipper_city": shippingState.shipperCity,
          "shipper_country": shippingState.shipperCountry,
          "shipper_province": shippingState.shipperProvince,
          "shipper_postal_code": shippingState.shipperPostalCode,
          "shipper_email": shippingState.shipperEmail,
          "shipper_phone": shippingState.shipperPhone,
          "shipper_instruction": shippingState.shipperInstructions,
          "shipper_confirm_delivery": shippingState.confirmDelivery,

          "receiver_name": shippingState.receiverName,
          "receiver_address1": shippingState.receiverAddress1,
          "receiver_address2": shippingState.receiverAddress2,
          "receiver_city": shippingState.receiverCity,
          "receiver_country": shippingState.receiverCountry,
          "receiver_province": shippingState.receiverProvince,
          "receiver_postal_code": shippingState.receiverPostalCode,
          "receiver_email": shippingState.receiverEmail,
          "receiver_phone": shippingState.receiverPhone,
          "receiver_instruction": shippingState.receiverInstrunctions,
          "receiver_signature_required": shippingState.signatureRequired,

          "description": shippingState.carrierPackageDesc,
          "weight_unit": "pound",
          "dimension_unit": "inch",
          "is_carrier_package": shippingState.packageType === "carrier",
          "carrier_package_type": shippingState.packageType,
          "height": shippingState.customerPackageHeight,
          "length": shippingState.customerPackageLength,
          "width": shippingState.customerPackageWidth,
          "weight": shippingState.carrierPackageWeight,

          "status": "Pending",
          "rate_info": shippingState.rateInfo.rateInfo,
          "envoy_rate_info": envoy_rate_info,
          "service_info": {
            //todo
          },
          "paid_info": {
            //todo
          },
          "custom": shippingState.custom,
          "ship_date": {
            "seconds": shippingState.shipDate,
            "nanoseconds": 0,
          },

          "customer_id": currentUser.uid,
        };

        // console.log(shippingInfo);

        if (UseTestData) {
          setLabel(processLabel(null))
        }
        else {
          const createLabel = firebase.functions().httpsCallable('createLabel');

          const params = {
            rate_id: shippingState.rateInfo.rateInfo.rate_id,
            db_payload: shippingInfo,
          }

          const rawLabel = await createLabel(params);
          if (!rawLabel) throw new Error("Label is null!!!");

          if (rawLabel?.data?.status >= 300) {
            console.log(rawLabel);
            //here save data to Firebase analytics.
            throw new Error(rawLabel.data.data.errors[0].message);
          }

          setLabel(processLabel(rawLabel))
        }

      } catch (error) {
        // toast.error(error.message);
        setNotice({ ...notice, open: true, message: error.message })
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }

    fetchData();
  }, [])

  const handleDone = () => {
    if (trackId) {
      history.goBack()
      return
    }
    dispatch(clearShippingInfo());
    setActiveStep(0);
  };

  const handleDownload = (e) => {
    window.open(label.labelPdfUrl, "_blank");
    // window.open("https://drive.google.com/file/d/1Urc6BOBFtW62p7_Ra0MMjYjj_eCcUfUZ/view?usp=sharing", "_blank");
    // window.location.href = label.labelPdfUrl;
    // const url = window.URL.createObjectURL(label.labelPdfUrl);
    // return url
  }

  if (isLoading)
    return (<Loading />);

  return (
    <>
      <NoticeBar notice={notice} setNotice={setNotice} />
      {!trackId &&
        <Typography variant="h5" align="center" component="h1" style={{ marginBottom: "25px", marginTop: "10px" }}>
          You have successfully placed your shipment!
      </Typography>
      }

      <Container maxWidth="md" component={Paper} variant="outlined">
        <Typography variant="h6" gutterBottom style={{ marginTop: "10px" }}>Shipment Details</Typography>
        <Divider style={{ width: "80%", }} />
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <Grid container>

              <Grid item xs={12} md={6} className={classes.bottomMargin}>
                <Typography variant="h6">Ship from:</Typography>
                <Typography>{label.shipperName}</Typography>
                <Typography>{label.shipperAddress1}</Typography>
                <Typography>{label.shipperAddress2}</Typography>
                <Typography>{label.shipperPostalCode}</Typography>
              </Grid>

              <Grid item xs={12} md={6} className={classes.bottomMargin}>
                <Typography variant="h6">Ship to:</Typography>
                <Typography>{label.receiverName}</Typography>
                <Typography>{label.receiverAddress1}</Typography>
                <Typography>{label.receiverAddress2}</Typography>
                <Typography>{label.receiverPostalCode}</Typography>
              </Grid>

              <Grid item xs={12} md={6} className={classes.bottomMargin}>
                <Typography variant="h6">Carrier/Service</Typography>
                {/* <Typography>{label.carrierCode}</Typography> */}
                <Typography>{label.serviceCode}</Typography>
              </Grid>

              <Grid item xs={12} md={6} className={classes.bottomMargin}>
                <Typography variant="h6">Tracking Number:</Typography>
                <Typography>{label.trackingNumber}</Typography>
              </Grid>

              <Grid item xs={12} md={6} className={classes.bottomMargin}>
                <Typography variant="h6">Estimated day of delivery</Typography>
                <Typography>{label.deliveryDay}</Typography>
              </Grid>

              <Grid item xs={12} md={6} className={classes.bottomMargin}>
                <Typography variant="h6">Status</Typography>
                <Typography>{label.status}</Typography>
              </Grid>

              <Grid item xs={12} md={6} className={classes.bottomMargin} style={{ marginBottom: "20px" }}>
                <Typography variant="h6">Payment:</Typography>
                <table className={classes.amountTable}>
                  <tbody>
                    <tr>
                      <td>Shipping Cost: </td>
                      <td className={classes.amountAlign}>{formatCurrency(label.shipmentCost)}</td>
                    </tr>

                    <tr>
                      <td>Other Surcharge: </td>
                      <td className={classes.amountAlign}>{formatCurrency(label.surcharge)}</td>
                    </tr>

                    <tr>
                      <td colSpan="2"><Divider style={{ width: "100%", }} /></td>
                    </tr>

                    <tr>
                      <td><b>Total Cost: </b></td>
                      <td className={classes.amountAlign}><b>{formatCurrency(label.totalAmount)}</b></td>
                    </tr>
                  </tbody>
                </table>

                {/* <Typography>Shipping Cost: {formatCurrency(label.shipmentCost)}</Typography>
                <Typography>Other Surcharge: {formatCurrency(label.surcharge)}</Typography>
                <Divider style={{ width: "75%", }} />
                <Typography>Total Cost: {formatCurrency(label.totalAmount)}</Typography> */}
              </Grid>

            </Grid>
          </Grid>
          <Grid item xs={12} md={4} style={{ alignSelf: "center" }}>
            <div style={{ textAlign: "center", }}>
              <a href={label.labelPdfUrl} target="_blank">
                <img src={label.labelPngUrl} alt="label" style={{ width: "230px" }} className={classes.label} />
              </a>
            </div>
            <div style={{ textAlign: "center", marginTop: "8px", }}>
              <Button startIcon={<CloudDownloadIcon />} color="secondary" disableElevation onClick={handleDownload}>Download label</Button>
            </div>
          </Grid>

        </Grid>
      </Container>

      <div style={{ textAlign: "center", padding: "20px 0" }}>
        <Button variant="contained" color="primary" onClick={handleDone} disableElevation>{trackId ? "Return" : "Done"}</Button>
      </div>
    </>
  );
}