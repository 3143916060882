import { Button, CircularProgress, Container, Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import { CardElement, useElements, useStripe, } from '@stripe/react-stripe-js';
import { Form, Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as yup from "yup";
import { BcCheckbox, BcTextField } from '../common/BcComponents';
import { AuthContext } from "../firebase/AuthContext";
import { firestore } from "../firebase/config";
import { saveCreditCard } from '../store/shippingSlice';
import firebase from 'firebase/app';
import NoticeBar from '../common/NoticeBar';

const validationSchema = yup.object({
  cardHolderName: yup.string().required("Card holder name is required.").min(3, "Name should have 3+ characters."),
});

const cardStyle = {
  style: {
    base: {
      color: "inherit",
      fontFamily: 'inherit',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "1px solid rgba(128,128,128, 0.5)",
      }
    },
    invalid: {
      color: "red",
      iconColor: "red"
    },
    // complete: {
    //     color: "green",
    //     iconColor: "green"
    // }
    // value: {
    //   card: "12321321"
    // }
  }
};

const useStyles = makeStyles((theme) => ({

  cardElement: {
    font: "inherit",
    color: "currentColor",
    border: "1px solid rgba(128,128,128, 0.5)",
    borderRadius: "5px",
    height: "1.1876em",
    margin: "0",
    display: "block",
    padding: "10.5px  14px",
    minWidth: "0",
    background: "none",
    boxSizing: "content-box",
    animationName: "mui-auto-fill-cancel",
    letterSpacing: "inherit",
    animationDuration: "10ms",
    webkitTapHighightColor: "transparent",
  },
  paper: {
    // margin: theme.spacing(2),
    padding: theme.spacing(1.4),
    // border: "1px solid red",
  },
  button: {
    // marginTop: theme.spacing(3),
    // marginLeft: theme.spacing(1),
  },
  progressButton: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    margin: "auto",
  },
}));

const setupIntent = firebase.functions().httpsCallable('setupIntent');
const addPaymentMethodDetails = firebase.functions().httpsCallable('addPaymentMethodDetails');

export default function Payment({ activeStep, setActiveStep }) {
  const stripe = useStripe();
  const elements = useElements();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { currentUser } = useContext(AuthContext);
  // const userState = useSelector((state) => state.user);
  const shipState = useSelector((state) => state.shipping);
  const [clientSecret, setClientSecret] = useState('');
  const [error, setError] = useState(null);
  const [creditCardValid, setCreditCardValid] = useState(false);
  const [cardExisting, setCardExisting] = useState(false)
  const [notice, setNotice] = useState({ message: "", open: false, severity: "error" })

  useEffect(() => {
    async function fetchData() {
      try {

        // if (!currentUser.uid) toast.error("UID is null.");
        const userDocRef = firestore.collection("Customer").doc(currentUser.uid)
        const userData = (await userDocRef.get()).data()
        const paymentIfo = userData.stripe_payment.payment_method
        // const sec = await generateClientSecret()

        if (paymentIfo) {
          const month = paymentIfo.card.exp_month.toString();
          const modifyMonth = month.length === 1 ? "0" + month : month
          const year = paymentIfo.card.exp_year.toString().slice(1, 3)
          const postal_code = paymentIfo.billing_details.address.postal_code
          const cardHolderName = userData.card_holder_name
          dispatch(saveCreditCard({ cardHolderName, postal_code, year, modifyMonth }))
          setCardExisting(true)
          setCreditCardValid(true)
        } else {
          //setup a new credit card.
          setCardExisting(false)

          const param = { firestore_customer_id: currentUser.uid }
          const intent = await setupIntent(param)
          setClientSecret(intent.data.payment_intent.client_secret)
        }
      } catch (error) {
        setNotice({ ...notice, open: true, message: error.message })
        // toast.error(error.message);
        console.error(error);
      }
    }

    fetchData();
  }, []);

  const handleSubmit = async (values, { setSubmitting }) => {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    if (cardExisting && values.useCurrentCard) {
      setActiveStep(activeStep + 1);
      return;
    }

    if (error) return;

    setSubmitting(true);
    // await sleep(5000);

    try {
      const result = await stripe.confirmCardSetup(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            // name: values.cardHolderName,
          },
        }
      });

      if (result.error) {
        console.log(result.error);
        setNotice({ ...notice, open: true, message: result.error.message })
        // toast.error(result.error);
        // Display result.error.message in your UI.
      }
      else {
        // The setup has succeeded. Display a success message and send
        // result.setupIntent.payment_method to your server to save the
        // card to a Customer

        const { payment_method } = result.setupIntent;
        const params = {
          payment_method_id: payment_method,
          firebase_customer_id: currentUser.uid,
        }

        const paymentResult = await addPaymentMethodDetails(params);
        const userDocRef = firestore.collection("Customer").doc(currentUser.uid)
        await userDocRef.update({ card_holder_name: values.cardHolderName })

        setActiveStep(activeStep + 1);
      }
    }
    catch (error) {
      setNotice({ ...notice, open: true, message: error.message })
      // toast.error(error.message);
      console.error(error)
    }
  };

  const handleCardChange = async (event) => {
    // console.log(event)
    setError(event.error ? event.error.message : "")
    if (!event.error && !event.empty && event.complete)
      setCreditCardValid(true)
  };

  const handleUseCurrentCardChange = async (e, handleChange) => {
    handleChange(e)
    e.target.value === 'true' ? setCreditCardValid(false) : setCreditCardValid(true)

    if (e.target.value === 'true' && !clientSecret) {
      try {
        const param = { firestore_customer_id: currentUser.uid }
        const intent = await setupIntent(param)
        setClientSecret(intent.data.payment_intent.client_secret)
      } catch (error) {
        // toast.error(error.message)
        setNotice({ ...notice, open: true, message: error.message })
      }
    }
  }

  return (
    <Formik initialValues={shipState} validationSchema={validationSchema} onSubmit={handleSubmit} enableReinitialize>
      {({ isSubmitting, values, handleChange, setFieldValue }) => (
        <Form autoComplete="off" noValidate>
          <NoticeBar notice={notice} setNotice={setNotice} />
          <Container maxWidth="sm" component={Paper} className={classes.paper} variant="outlined">
            <Typography variant="h6" style={{ marginBottom: "1rem" }}>
              Credit Card/ Billing Info
            </Typography>
            <Grid container spacing={2}>

              {cardExisting &&
                <Grid item xs={12}>
                  <BcCheckbox name="useCurrentCard" label="Use current card" onChange={(e) => handleUseCurrentCardChange(e, handleChange)} disabled={isSubmitting} />
                </Grid>
              }

              {/* <Grid item xs={12}>
                <BcCheckbox name="useCurrentCard" label="Use current card" onChange={(e) => handleUseCurrentCardChange(e, handleChange)} disabled={isSubmitting} />
              </Grid> */}

              <Grid item xs={12}>
                <BcTextField label="Card holder name" name="cardHolderName" autoComplete="cc-name" required disabled={(cardExisting && values.useCurrentCard) || isSubmitting} />
              </Grid>


              {cardExisting && values.useCurrentCard ? <>
                <Grid item xs={8}>
                  <BcTextField name="last4" label="Card Number" disabled />
                </Grid>

                <Grid item xs={2}>
                  <BcTextField name="expire_date" disabled label="MM/YY" />
                </Grid>

                <Grid item xs={2}>
                  <BcTextField name="postal_code" disabled label="Zip" />
                </Grid>
              </> :
                <Grid item xs={12}>
                  <CardElement options={cardStyle} className={classes.cardElement} onChange={handleCardChange} options={{ disabled: isSubmitting }} />
                  {error ? <span style={{ color: "red" }}>{error}</span> : ""}
                </Grid>
              }

              <Grid item xs={12}>
                <Alert severity="info">You will be charged when label scanned by carrier.</Alert>
              </Grid>
            </Grid>

          </Container>

          <Container maxWidth="sm" style={{ padding: "20px 0 20px 0", }}>
            <Grid container justify="flex-end">
              <Button variant="outlined" color="primary" disabled={isSubmitting} style={{ marginRight: "10px" }} onClick={() => setActiveStep(activeStep - 1)} >Back</Button>
              <div style={{ position: "relative", }}>
                <Button variant="contained" color="primary" className={classes.button} type="submit" disabled={!stripe || isSubmitting || !creditCardValid}>
                  {isSubmitting ? "processing..." : "next"}
                </Button>
                {isSubmitting && <CircularProgress size={24} className={classes.progressButton} color="secondary" />}
              </div>

            </Grid>

            {/* <SubmitButton activeStep={activeStep} setActiveStep={setActiveStep} isSubmitting={!stripe || error} /> */}
          </Container>

        </Form>)}
    </Formik>
  );

  // return (
  //   <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
  //     {(props) => (
  //       <Form autoComplete="on" noValidate>
  //         <Container maxWidth="md" component={Paper} className={classes.paper} variant="outlined">
  //           <Typography variant="h6">
  //             Credit Card/ Billing Info
  //           </Typography>
  //           <Grid container spacing={2}>
  //             <Grid item xs={12}>
  //               <BcCheckbox name="useHomeAddress" label="Same as home address" />
  //             </Grid>
  //             <Grid item xs={12}>
  //               <BcTextField label="Credit Card Number" name="ccCardNumber" required type="number" autoComplete="cc-number" />
  //             </Grid>

  //             <Grid item xs={12} md={6}>
  //               <BcTextField label="Expiration Date" name="ccExpiryDate" required type="number" autoComplete="cc-exp" />
  //             </Grid>

  //             <Grid item xs={12} md={6}>
  //               <BcTextField label="Security Code (CVV)" name="ccSecurityCode" required type="number" autoComplete="cc-csc" />
  //             </Grid>

  //             <Grid item xs={12}>
  //               <BcTextField label="Cardholder Name" name="ccHolderName" required autoComplete="cc-name" />
  //             </Grid>

  //             <Address {...props} />
  //           </Grid>

  //         </Container>

  //         <Container maxWidth="md" style={{ padding: "0px 0 20px 0", }} >
  //           <SubmitButton activeStep={activeStep} setActiveStep={setActiveStep} />
  //         </Container>

  //       </Form>)}
  //   </Formik>
  // );
}