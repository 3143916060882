import { Box, Typography } from '@material-ui/core';
import firebase from 'firebase/app';
import { Form, Formik } from 'formik';
import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
import { BcCheckbox } from '../common/BcComponents';
import NoticeBar from '../common/NoticeBar';
import SubmitButton from '../common/SubmitButton';
import { AuthContext } from "../firebase/AuthContext";
import { firestore } from "../firebase/config";

export default function Terms({ activeStep, setActiveStep }) {
  const { signup } = useContext(AuthContext);
  const initialValues = useSelector((state) => state.user);
  const history = useHistory();
  const [notice, setNotice] = useState({ message: "", open: false, severity: "error" });

  const handleSubmit = async (values) => {
    try {
      const { email, password } = values;
      const user = await signup(email, password);
      const userId = user.user.uid;
      //todo: add a batched operation here. add loading...

      const customer = {
        address1: values.address1,
        address2: values.address2,
        addresses: [],
        city: values.city,
        country: values.country,
        create_date: firebase.firestore.FieldValue.serverTimestamp(),
        customer_type: "regular",
        price_chart: "Regular_Rate",
        search_fields: { email: values.email.toLowerCase(), first_name: values.firstName.toLowerCase(), last_name: values.lastName.toLowerCase() },
        customer_id: userId,
        email: values.email,
        first_name: values.firstName,
        last_name: values.lastName,
        notes: "",
        phone: values.phone,
        postal_code: values.postalCode,
        price_rate: 1,
        province: values.province,
        status: "active",
        card_holder_name: `${values.firstName} ${values.lastName}`,
      };

      await firestore.collection("Customer").doc(userId).set(customer);
      // setNotice({ message: "successful!", open: true, severity: "success" });
      toast.success("You signed up successfully!");
      history.push("/shipping")

    } catch (error) {
      console.log(error);
      setNotice({ ...notice, open: true, message: error.message });
      // toast.error(`Failed to sign up. Reason: ${error.message}`);
    }
  };

  return (
    <Formik onSubmit={handleSubmit} initialValues={initialValues}>
      {() => (
        <Form autoComplete="on" noValidate>
          <NoticeBar notice={notice} setNotice={setNotice} />

          <Box maxHeight="500">
            <Typography component="h6" variant="subtitle1" gutterBottom>
              Disclaimer and Privacy Statement
            </Typography>

            <Typography component="p" variant="body1" paragraph>
              These Terms and Conditions constitute a legally binding agreement made between you, whether personally or on behalf of an entity (“you”) and [business entity name] (“we,” “us” or “our”), concerning your access to and use of the [website name.com] website as well as any other media form, media channel, mobile website or mobile application related, linked, or otherwise connected thereto (collectively, the “Site”).
            </Typography>

            {/* <Typography component="p" variant="body1" paragraph>
              We will alert you about any changes by updating the “Last updated” date of these Terms and Conditions, and you waive any right to receive specific notice of each such change.
            </Typography>

            <Typography component="p" variant="body1" paragraph>
              It is your responsibility to periodically review these Terms and Conditions to stay informed of updates. You will be subject to, and will be deemed to have been made aware of and to have accepted, the changes in any revised Terms and Conditions by your continued use of the Site after the date such revised Terms and Conditions are posted.
            </Typography>

            <Typography component="p" variant="body1" paragraph>
              The information provided on the Site is not intended for distribution to or use by any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation or which would subject us to any registration requirement within such jurisdiction or country.
            </Typography>

            <Typography component="p" variant="body1" paragraph>
              Accordingly, those persons who choose to access the Site from other locations do so on their own initiative and are solely responsible for compliance with local laws, if and to the extent local laws are applicable.
            </Typography>

            <Typography component="p" variant="body1" paragraph>
              These terms and conditions were generated by Termly’s Terms and Conditions Generator.
            </Typography> */}

            <BcCheckbox label="I accept" name="acceptTerms" />

          </Box>

          <SubmitButton text="Submit" activeStep={activeStep} setActiveStep={setActiveStep} />
        </Form>
      )}
    </Formik>
  );
}