import { Button, CircularProgress, Radio, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { lighten, makeStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';
import axios from "axios";
import firebase from 'firebase/app';
import "firebase/functions";
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import processRate from '../businessLogic/processRates';
import { formatCurrency, UseTestData } from '../businessLogic/utility';
import NoticeBar from '../common/NoticeBar';
import { AuthContext } from "../firebase/AuthContext";
import { firestore } from "../firebase/config";
import { saveProcessedRates } from '../store/shippingSlice';


const headCells = [
  { id: 'rateId', numeric: false, disablePadding: true, label: 'rateId', },
  { id: 'serviceType', numeric: false, disablePadding: true, label: 'Service' },
  { id: 'deliveryDays', numeric: true, disablePadding: true, label: 'Delivery Days' },
  { id: 'shippingAmount', numeric: true, disablePadding: true, label: 'Shipping Cost' },
  { id: 'surcharge', numeric: true, disablePadding: true, label: 'Surcharges' },
  { id: 'totalToCustomer', numeric: true, disablePadding: false, label: 'Total Cost' },
  { id: 'branchTotal', numeric: true, disablePadding: false, label: 'Branch Cost' },
];

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  hiddenCell: {
    display: "none",
  },
  tableRow: theme.palette.type === 'light'
    ? {
      "&.Mui-selected, &.Mui-selected:hover": {
        backgroundColor: lighten(theme.palette.primary.light, 0.85),
        "& > .MuiTableCell-root": {
          color: theme.palette.primary.main,
        }
      }
    }
    : {
      "&.Mui-selected, &.Mui-selected:hover": {
        backgroundColor: lighten(theme.palette.primary.dark, 0.85),
        "& > .MuiTableCell-root": {
          color: theme.palette.primary.main,
        }
      }
    },

}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}


function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort, customerType } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell style={{ backgroundColor: "#eee", }}>
        </TableCell>
        {headCells.map((headCell, index) => {
          if (headCell.id === 'branchTotal' && customerType.toLowerCase() === 'regular') return null;
          return <TableCell
            key={index}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
            className={headCell.id === "rateId" ? classes.hiddenCell : ""}
            style={{ backgroundColor: "#eee", }}
          // style={{ padding: "10px 0", }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        })}
      </TableRow>
    </TableHead >
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function RateTable(props) {
  const { setService } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { currentUser } = useContext(AuthContext);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('totalToCustomer');
  const [selected, setSelected] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const shippingState = useSelector((state) => state.shipping);
  const { customerType } = shippingState;
  // const [customer, setCustomer] = useState({});
  const [notice, setNotice] = useState({ message: "", open: false, severity: "error" });

  useEffect(() => {

    async function fetchData() {
      setIsLoading(true);
      const { contents, non_delivery } = shippingState;

      const customs_items = shippingState.customs_items.map(item => {
        const { description, quantity, harmonized_tariff_code, sku } = item;
        return {
          description, quantity,
          value: { currency: "cad", amount: item.value },
          harmonized_tariff_code,
          country_of_origin: shippingState.shipperCountry,
          unit_of_measure: "in",
          sku,
          sku_description: "",
        };
      });

      const processedCustom = shippingState.shipperCountry === shippingState.receiverCountry ? null : { contents, non_delivery, customs_items }

      const shipment_parameters = {
        "rate_options": {
          "carrier_ids": [
            "se-538063",
            "se-537961",
            "se-537955",
          ]
        },
        "shipment": {
          "validate_address": "no_validation", // todo: validate_and_clean
          "ship_date": new Date(shippingState.shipDate).toISOString(),
          "confirmation": shippingState.signatureRequired ? "signature" : "none",
          "customs": processedCustom,
          "ship_from": {
            "name": shippingState.shipperName,
            "phone": shippingState.shipperPhone,
            // "company_name": "Example Corp.",  // no company name???
            "address_line1": shippingState.shipperAddress1,
            "address_line2": shippingState.shipperAddress2,
            "city_locality": shippingState.shipperCity,
            "postal_code": shippingState.shipperPostalCode,
            "country_code": shippingState.shipperCountry,
            "state_province": shippingState.shipperProvince,
            "address_residential_indicator": "unknown",
          },
          "ship_to": {
            "name": shippingState.receiverName,
            "phone": shippingState.receiverPhone,
            "address_line1": shippingState.receiverAddress1,
            "address_line2": shippingState.receiverAddress2,
            "city_locality": shippingState.receiverCity,
            "state_province": shippingState.receiverProvince,
            "postal_code": shippingState.receiverPostalCode,
            "country_code": shippingState.receiverCountry,
            "address_residential_indicator": "unknown",
          },
          "package_types": [
            shippingState.packageTypeFedex, shippingState.packageTypePurolator, shippingState.packageTypeDhl,
          ],
          "packages": [
            {
              "weight": {
                "value": shippingState.packageType === "carrier" ? shippingState.carrierPackageWeight : shippingState.customerPackageWeight,
                "unit": "pound"
              },
              "dimensions": {
                "length": shippingState.customerPackageLength || 1,
                "width": shippingState.customerPackageWidth || 1,
                "height": shippingState.customerPackageHeight || 1,
                "unit": "inch",
              }
            }
          ],
        }
      }

      const access_key = 'be40588b80d3941bfa870f7af6c0be29';
      try {
        // const exchanges = await axios.get("https://api.exchangeratesapi.io/latest?base=USD");
        const exchanges = await axios.get(`https://api.exchangeratesapi.io/v1/latest?access_key=${access_key}&base=usd`);
        console.log(exchanges)
        var cadExchangeRate = exchanges.data.rates.CAD;

        const customerData = (await firestore.collection("Customer").doc(currentUser.uid).get()).data();
        var customer = {
          customer_type: customerData.customer_type,
          price_chart: customerData.price_chart,
        };

        const branch_rate = (await firestore.collection("PriceChart").doc("Branch_Rate").get()).data();
        const regular_rate = (await firestore.collection("PriceChart").doc("Regular_Rate").get()).data();

        customer.branch_rate = branch_rate;
        customer.regular_rate = regular_rate;

      } catch (error) {
        console.error(error);
        setNotice({ ...notice, open: true, message: error.message })
        return
      }

      try {
        if (UseTestData) {
          var processedRates = processRate(cadExchangeRate, customer);
          setRows(processedRates);
        }
        else {
          const getShippingRate = firebase.functions().httpsCallable('getShippingRate');
          const rawRates = await getShippingRate({ data: shipment_parameters });
          // console.log(JSON.stringify(rawRates))

          if (rawRates && rawRates.data && rawRates.data.status !== 200) {
            throw new Error(rawRates.data.data.errors[0].message);
          }


          var processedRates = processRate(cadExchangeRate, customer, rawRates);

        }

        setRows(processedRates);
        dispatch(saveProcessedRates({ processedRates, customerType: customer.customer_type, custom: processedCustom }));
      } catch (error) {
        // toast.error(error.message);
        setNotice({ ...notice, open: true, message: error.message });
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }

    shippingState.processedRates && shippingState.skipRequery ? setRows(shippingState.processedRates) : fetchData();
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClick = (event, rate) => {
    const rateId = rate.rateId;

    let newSelected = [];
    newSelected.push(rateId);
    setSelected(newSelected);

    // console.log(rate.totalToCustomer, rate);
    setService({ totalAmount: formatCurrency(rate.totalToCustomer), rate });
    // props.setFinalAmount(formatAmount(row.totalToCustomer));
    // alert(rateId);
  };

  const handleShowPrice = (e, branchRate, isShow = true) => {
    if (isShow)
      e.target.innerText = formatCurrency(branchRate);
    else
      e.target.innerText = "SHOW";
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  return (
    <TableContainer component={Paper} variant="outlined" margin="20">
      <NoticeBar notice={notice} setNotice={setNotice} />
      <Table
        className={classes.table}
        aria-labelledby="tableTitle"
        size="medium"
        aria-label="enhanced table"
      >
        <EnhancedTableHead
          classes={classes}
          numSelected={selected.length}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          rowCount={rows.length}
          customerType={customerType}
        />

        {isLoading &&
          <TableBody>
            <TableRow>
              <TableCell colSpan="7" align="center">
                <Typography variant="h6" color="secondary" gutterBottom>One moment, querying...</Typography>
                <CircularProgress color="secondary" />
                <Skeleton />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
              </TableCell>
            </TableRow>
          </TableBody>
        }

        <TableBody>
          {stableSort(rows, getComparator(order, orderBy))
            .map((row, index) => {
              const isItemSelected = isSelected(row.rateId);
              const labelId = `enhanced-table-checkbox-${index}`;
              return (
                <TableRow
                  hover
                  onClick={(event) => handleClick(event, row)}
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row.rateId}
                  selected={isItemSelected}
                  className={classes.tableRow}
                >
                  <TableCell id={labelId} scope="row" className={classes.hiddenCell} >{row.rateId}</TableCell>
                  <TableCell padding="checkbox">
                    <Radio checked={isItemSelected} color="primary" />
                  </TableCell>
                  <TableCell padding="none">{row.serviceType}</TableCell>
                  <TableCell padding="none" align="right">{row.deliveryDays}</TableCell>
                  <TableCell padding="none" align="right">{formatCurrency(row.shippingAmount)}</TableCell>
                  <TableCell padding="none" align="right">{formatCurrency(row.surcharge)}</TableCell>
                  <TableCell padding="default" align="right">{formatCurrency(row.totalToCustomer)}</TableCell>

                  {customerType.toLowerCase() !== "regular" &&
                    <TableCell align="right" padding="default">
                      <Button variant="contained" color="primary" size="small" disableElevation disableRipple
                        onMouseDown={(e) => { handleShowPrice(e, row.totalToBranch) }}
                        onMouseUp={(e) => { handleShowPrice(e, row.totalToBranch, false) }}>Show</Button> </TableCell>
                  }
                </TableRow>
              );
            })}
        </TableBody>

      </Table>
    </TableContainer>
  );
}
