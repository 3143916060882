import { Grid } from '@material-ui/core';
import { Form, Formik } from 'formik';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from "yup";
import Address, { addressValidationSchema } from '../common/Address';
import { BcTextField } from '../common/BcComponents';
import SubmitButton from '../common/SubmitButton';
import { saveUserInfo } from '../store/userSlice';

const validationSchema = yup.object({
  firstName: yup.string().required("First name is required."),
  lastName: yup.string().required("Last name is required."),
  phone: yup.string().required("Phone is required."),
}).shape(addressValidationSchema);


export default function UserInfo({ activeStep, setActiveStep }) {
  const dispatch = useDispatch();
  const initialValues = useSelector((state) => state.user);

  const handleSubmit = (values) => {
    dispatch(saveUserInfo(values));
    setActiveStep(activeStep + 1);
  }

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
      {(props) => (
        <Form autoComplete="on" noValidate>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <BcTextField label="First Name" name="firstName" required autoComplete="given-name" />
            </Grid>

            <Grid item xs={12} md={6}>
              <BcTextField label="Last Name" name="lastName" required autoComplete="family-name" />
            </Grid>

            <Address {...props} />

            <Grid item xs={12} md={6}>
              <BcTextField label="Phone" name="phone" required autoComplete="phone" />
            </Grid>

          </Grid>

          <SubmitButton activeStep={activeStep} setActiveStep={setActiveStep} />

          {/* <h5>My data:</h5>
          <pre>{JSON.stringify(props.values, null, 4)}</pre> */}

        </Form>
      )}
    </Formik>
  );
}