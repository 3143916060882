import { createSlice } from '@reduxjs/toolkit';
import axios from "axios";

const initialState = {
  cadExchangeRate: 0,
  error: null,
};

const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    saveCadExchange: (state, action) => {
      state.cadExchangeRate = action.payload;
      state.error = null;
    },
    saveError: (state, action) => {
      state.error = action.payload;
    },
  },

});

export const {
  saveCadExchange,
  saveError,
} = commonSlice.actions;

// export const selectUser = useSelector((state) => state.user);
// export const selectUser = state => state.user.email;

export const fetchExchangeRate = () => async dispatch => {
  try {
    const exchanges = await axios.get("https://api.exchangeratesapi.io/latest?base=USD");
    const cadRate = exchanges.data.rates.CAD;
    dispatch(saveCadExchange(cadRate));
  } catch (error) {
    dispatch(saveError(error.toString()));
  }
}

export default commonSlice.reducer;