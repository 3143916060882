import { Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

export default function SubmitButton({ showBackButton, activeStep, setActiveStep, text, isSubmitting }) {
  const classes = useStyles();
  const buttonText = text ? text : "Next"
  const isShowBackButton = showBackButton === undefined ? true : showBackButton;

  return (
    <Grid container justify="flex-end">
      {isShowBackButton && <Button variant="outlined" color="primary" className={classes.button} onClick={() => setActiveStep(activeStep - 1)}  >Back</Button>}
      <Button variant="contained" color="primary" className={classes.button} type="submit" disabled={isSubmitting}>{buttonText}</Button>
    </Grid>
  );
}