
import { combineReducers } from "redux";
import countryReducer from "./countrySlice";
import shippingReducer from "./shippingSlice";
import userReducer from "./userSlice";
import commonReducer from "./commonSlice";

const rootReducer = combineReducers({
  shipping: shippingReducer,
  user: userReducer,
  country: countryReducer,
  common: commonReducer,
});

export default rootReducer;