import { createMuiTheme, CssBaseline, ThemeProvider } from "@material-ui/core";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PageNotFound from "./common/PageNotFound";
import PrivateRoute from "./common/PrivateRoute";
import Track from "./common/Track";
import Account from "./components/Account";
import PrintLabel from "./components/PrintLabel";
import Shipping from './components/Shipping';
import Support from "./components/Support";
import Tracking from "./components/Tracking";
import { AuthProvider } from "./firebase/AuthContext";
import store from "./store/store";
import ForgetPassword from "./user/ForgetPassword";
import Login from "./user/Login";
import Logout from "./user/Logout";
import Signup from "./user/Signup";

const envoyBlue = "#123c63";
const envoyOrange = "#fa7847";

const theme = createMuiTheme({
  props: {
    MuiTextField: {
      size: "small",
      variant: "outlined",
      fullWidth: true,
    },
    MuiButton: {
      color: "primary",
      variant: "contained",
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        a: {
          color: envoyBlue,
          '&:link, &:visited': {
            textDecoration: "none",
          },
          '&:hover': {
            textDecoration: "underline",
          },
        },
      }
    },
  },
  palette: {
    type: "light",
    primary: {
      main: envoyBlue,
    },
    secondary: {
      main: envoyOrange,
      contrastText: "#fff",
    },
    text: {
      primary: envoyBlue,
    },

  },
  typography: {
    // fontFamily: 'Arial, Roboto',

  },

});

export default function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <ToastContainer position="bottom-center"
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover={false} />
        <AuthProvider>
          <BrowserRouter>
            <Switch>
              <Route exact path="/" component={Login} />
              <Route path="/signup" component={Signup} />
              <Route path="/login" component={Login} />
              <PrivateRoute path="/shipping" component={Shipping} />
              <PrivateRoute path="/track/:id" component={Track} />
              <PrivateRoute exact path="/tracking" component={Tracking} />
              <PrivateRoute path="/account" component={Account} />
              <PrivateRoute path="/support" component={Support} />
              <Route path="/forget-password" component={ForgetPassword} />
              <PrivateRoute path="/logout" component={Logout} />
              <Route path="*" component={PageNotFound} />

            </Switch>
          </BrowserRouter>
        </AuthProvider>
      </ThemeProvider>
    </Provider>
  );
}

