import { Container, Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from "yup";
import NoticeBar from '../common/NoticeBar';
import SubmitButton from '../common/SubmitButton';
import { saveRateInfo, selectReceiverAddress1, selectReceiverAddress2, selectReceiverAddress3, selectShipperAddress1, selectShipperAddress2, selectShipperAddress3 } from '../store/shippingSlice';
import RateTable from './RateTable';


const useStyles = makeStyles((theme) => ({
  default: {

  },
  paper: {
    margin: theme.spacing(2, 2, 0, 2),
    padding: theme.spacing(3, 2),
    // border: "1px solid red",
  },
  // button: {
  //   paddingBottom: "20px",
  //   paddingRight: "20px",
  // },
  rateTable: {
    // padding: theme.spacing(0, 2, 2, 0),
    padding: "20px",
  },

}));

const validationSchema = yup.object({
  // contents: yup.string().required("Contents is required."),
  // non_delivery: yup.string().required("NonDelivery is required."),
});

export default function Rate({ activeStep, setActiveStep }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const shippingState = useSelector((state) => state.shipping);
  const [service, setService] = useState({ totalAmount: "$0", rate: null });
  const [notice, setNotice] = useState({ message: "", open: false, severity: "error" });

  const handleSubmit = async (values) => {

    if (!service.rate) {
      setNotice({ ...notice, open: true, message: "Please select a service." });
      return;
    }

    // console.log(service.rate);
    dispatch(saveRateInfo(service.rate));
    setActiveStep(activeStep + 1);

  };


  return (
    <>
      <Container component={Paper} variant="elevation" elevation={0} style={{ width: "900px", padding: "20px", }}>
        <Grid container>
          <Grid item >
            <table>
              <tbody>
                <tr>
                  <td><Typography variant="h6">From:</Typography></td>
                  <td><Typography variant="h6">{shippingState.shipperName}</Typography> </td>
                </tr>
                <tr>
                  <td></td>
                  <td>{selectShipperAddress1(shippingState)}</td>
                </tr>
                <tr>
                  <td></td>
                  <td>{selectShipperAddress2(shippingState)}</td>
                </tr>
                <tr>
                  <td></td>
                  <td>{selectShipperAddress3(shippingState)}</td>
                </tr>
              </tbody>
            </table>
          </Grid>

          <Grid item style={{ width: "260px", textAlign: "center", alignSelf: "center" }}>
            <img src="/truck.png" alt="" style={{ width: "100px", }} />
          </Grid>

          <Grid item >
            <table>
              <tbody>
                <tr>
                  <td><Typography variant="h6">To:</Typography></td>
                  <td><Typography variant="h6">{shippingState.receiverName}</Typography> </td>
                </tr>
                <tr>
                  <td></td>
                  <td>{selectReceiverAddress1(shippingState)}</td>
                </tr>
                <tr>
                  <td></td>
                  <td>{selectReceiverAddress2(shippingState)}</td>
                </tr>
                <tr>
                  <td></td>
                  <td>{selectReceiverAddress3(shippingState)}</td>
                </tr>
              </tbody>
            </table>
          </Grid>
        </Grid>
      </Container>

      <Formik initialValues={shippingState} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {(props) => (
          <Form autoComplete="on" noValidate>
            <NoticeBar notice={notice} setNotice={setNotice} />

            <div className={classes.rateTable} id="rateTable">
              <RateTable {...props} setService={setService} />
            </div>

            <Typography variant="h6" align="center">
              Total Cost: {service.totalAmount}
            </Typography>


            <div style={{ padding: "0 20px 20px 0", }}>

              <SubmitButton activeStep={activeStep} setActiveStep={setActiveStep} />
            </div>

          </Form>
        )}
      </Formik>



    </>
  );
}