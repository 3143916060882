

export const sleep = (milliseconds = 1000) => new Promise((r) => setTimeout(r, milliseconds));

const currency = {
  country: "en-CA",
  format: {
    style: "currency",
    currency: "CAD",
  },
};

export function formatCurrency(value) {
  return value.toLocaleString(currency.country, currency.format);
}

export const Carriers = {
  FedEx: { name: "FedEx", code: "fedex", id: "se-538063" },
  DHL: { name: "DHL Express Canada", code: "dhl_express_canada", id: "se-537961" },
  Purolator: { name: "Purolator Canada", code: "purolator_ca", id: "se-537955" },
}

// export const getExchange = () => {
//   await axios.get("https://api.exchangeratesapi.io/latest?base=USD")
// }

export const UseTestData = false;